import axios from "axios";
import { BASEURL } from "./constantes";

const getUserService = async () => {
    const response = await axios.get(BASEURL + "/users");
    if (response.status === 200) {
        return response.data;
    } else {
        return [];
    }

};


const addUserService = async (data) => {
    try {
        const response = await axios.post(BASEURL + "/users", data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

const updateUserService = async (id, data) => {
    try {
        const response = await axios.patch(BASEURL + "/users/" + id, data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};



const deleteUserService = async (id) => {
    try {
        const response = await axios.delete(BASEURL + "/users/" + id);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }

};

const getUsersDownload = async () => {
    try {
        const response = await axios.get(BASEURL + "/users/download");
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
}

export { getUserService, deleteUserService, updateUserService, addUserService, getUsersDownload };
