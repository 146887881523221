
import * as React from 'react';
import { CustomDrawer } from '../Drawer/CustomDrawer';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Search } from '@material-ui/icons';
import { StripedDataGrid } from '../../utils/StripedDataGrid';


const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'firstName', headerName: 'First name', width: 130 },
    { field: 'lastName', headerName: 'Last name', width: 130 },
    {
        field: 'age',
        headerName: 'Age',
        type: 'number',
        width: 90,
    },
    {
        field: 'fullName',
        headerName: 'Full name',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 160,
        valueGetter: (params) =>
            `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
];

const rows = [
    { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
    { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
    { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
    { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
    { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
    { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
    { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
    { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];




export const ExamplePage = () => {
    return (
        <div>
            <CustomDrawer>

                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton style={{ padding: '0px' }}>
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                    id="search"
                    label="Buscar"
                    type='text'
                    autoComplete='off'
                /*  value={username}
                 onChange={onChangeUsername} */
                />

                <div style={{ height: 400, width: '100%', marginTop: '20px' }}>
                    <StripedDataGrid
                        rows={rows}
                        columns={columns}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}

                        pageSizeOptions={[5, 10]}
                        checkboxSelection
                    />
                </div>
            </CustomDrawer>
        </div>


    );
}