import { Button } from "@material-ui/core";
import { SaveOutlined } from "@material-ui/icons";
import { DeleteOutline, FileOpenRounded } from "@mui/icons-material";
import ModalClose from "@mui/joy/ModalClose";
import {
  Autocomplete,
  Box,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { getIncidenceByIdService } from "../../services/incidence_service";
import {
  addReportService,
  updateReportService,
} from "../../services/report_service";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const ReportModal = ({
  open,
  handleClose,
  data,
  getData,
  projects,
  incidences,
  statusIncidences,
  users,
  modalView,
}) => {
  const [fecha, setFecha] = useState("");
  const [comentarios, setComentarios] = useState("");
  const [user, setUser] = useState("");
  const [project, setProject] = useState("");
  const [incidence, setIncidence] = useState("");
  const [statusIncidence, setStatusIncidence] = useState("");
  const [responsible, setResponsible] = useState("");

  const [enabled, setEnabled] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [enableImputfile, setEnableImputfile] = useState(false);
  const [sendService, setSendService] = useState(false);
  //const [modalView, setmodalView] = useState(false);

  const handleFileChange = (e) => {
    const files = e.target.files;
    const filesArray = [];
    if (files.length + selectedFiles.length <= 4) {
      for (const file of files) {
        console.log("file", file);
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          // console.log('reader', reader);
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        }).then((res) => {
          console.log("resfile", res);
          console.log("resfile2", URL.createObjectURL(file));
          const payloadimg = {
            fileName: file.name,
            fileValue: res,
            urlFile: URL.createObjectURL(file),
            insert: true,
          };
          filesArray.push(payloadimg);
          setSelectedFiles([...selectedFiles, ...filesArray]);
          if (selectedFiles.length + filesArray.length > 3) {
            setEnableImputfile(true);
          }
        });
      }
    }
  };

  const removeFile = (index) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
    if (selectedFiles.length <= 4) {
      setEnableImputfile(false);
    }
  };

  const onHandleClick = async () => {
    var response;
    var payload = {
      fecha,
      comment: comentarios,
      responsibleId: responsible,
      selectedFiles: selectedFiles.length > 0 ? selectedFiles : [],
    };
    setSendService(true);
    if (data !== null) {
      if (incidence?.id) {
        payload.userId = user;
        payload.incidenceId = incidence?.id;
        payload.projectId = project;
      }

      if (statusIncidence?.id) {
        payload.incidentStatusId = statusIncidence.id;
      }
      response = await updateReportService(data?.id, payload);
    } else {
      payload.userId = user;
      payload.incidenceId = incidence?.id;
      payload.projectId = project;
      payload.responsibleId = responsible;
      payload.selectedFiles = selectedFiles.length > 0 ? selectedFiles : [];
      response = await addReportService(payload);
    }
    handleClose();
    if (response?.statusCode === 201 || response?.statusCode === 200) {
      await Swal.fire(
        "OK",
        data === null ? "Creado correctamente" : "Actualizado correctamente",
        "success"
      );
      getData();
    } else {
      Swal.fire("Error", response?.message, "error");
    }
  };

  const onChangeIncidence = async (data) => {
    if (data) {
      const response = await getIncidenceByIdService(data?.id);
      setIncidence(data);
      setProject(response?.project?.id);
      setUser(response?.user?.id);
      setResponsible(response?.responsible?.id);
    } else {
      setProject("");
      setUser("");
      setIncidence("");
    }
  };

  const onChangeStatusIncidence = (data) => {
    if (data) {
      setStatusIncidence(data);
    } else {
      setStatusIncidence("");
    }
  };

  useEffect(() => {
    if (data !== null) {
      setFecha(data?.fecha);
      setIncidence(data?.incident?.title?{
        'id': data?.incident?.id,
        'label': data?.incident?.title,
        'value': data?.incident?.id 
      }:null);
      setProject(data?.project?.id);
      setStatusIncidence(data?.incidentStatus?.campo?{
        'id': data?.incidentStatus?.id,
                'label': data?.incidentStatus?.valor,
                'value': data?.incidentStatus?.id,
      }:null);
      setComentarios(data?.comment);
      setResponsible(data?.responsible?.id);
      setUser(data?.user?.id);
      if (data?.filestext) {
        console.log("data?.filestext", data?.filestext);
        const filsSplit = data.filestext.split("|||");
        if (Array.isArray(filsSplit)) {
          const arrayFiles = [];
          for (const file of filsSplit) {
            console.log("file for", file);
            const fileobject = file.split("||");
            console.log("fileobject for ", fileobject);
            const payloadimg = {
              fileName: fileobject[1],
              fileValue: null,
              urlFile: fileobject[0],
              insert: false,
            };
            arrayFiles.push(payloadimg);
          }
          setSelectedFiles([...selectedFiles, ...arrayFiles]);
          if (arrayFiles.length > 3) {
            setEnableImputfile(true);
          }
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (
      fecha !== "" &&
      incidence !== "" &&
      statusIncidence !== "" &&
      comentarios !== ""
    ) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, [fecha, statusIncidence, incidence, comentarios]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={window.matchMedia('(max-width: 800px)').matches?{maxHeight:"700px",overflowY:"scroll"}:{}}>
        <div style={{ display: "flex" }}>
            <ModalClose variant="plain" sx={{ m: 1 }} onClick={handleClose} />
            <Typography
              variant="h5"
              style={{ marginBottom: "20px", fontWeight: "revert" }}
            >
              {modalView ? "" : data === null ? "Agregar" : "Editar"} Reporte
            </Typography>
          </div>
          <Grid container spacing={4} style={{}}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Autocomplete
                disabled={modalView || sendService}
                value={incidence}
                fullWidth
                id="combo-box-type-incidence"
                options={incidences}
                onChange={(_, value) => {
                  onChangeIncidence(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="incidenceId"
                    label="Incidencia"
                    disabled={modalView || sendService}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Autocomplete
                disabled={modalView || sendService}
                value={statusIncidence}
                fullWidth
                id="combo-box-status-incidence"
                options={statusIncidences}
                onChange={(_, value) => {
                  onChangeStatusIncidence(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="statusIncidence"
                    label="Estado"
                    disabled={modalView || sendService}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                value={fecha}
                onChange={(e) => {
                  console.log(e.target.value)
                  setFecha(e.target.value)}}
                fullWidth
                id="fecha"
                type="date"
                autoComplete="off"
                disabled={modalView || sendService}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                value={comentarios}
                onChange={(e) => setComentarios(e.target.value)}
                fullWidth
                id="comentarios"
                label="Comentarios"
                type="text"
                autoComplete="off"
                disabled={modalView || sendService}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {(!modalView && !sendService) ? (
                !enableImputfile ? (
                  <div>
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg, .png, .docx"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      id="fileInput"
                      multiple
                      disabled={modalView || sendService}
                    />
                    <label htmlFor="fileInput" style={{ cursor: "pointer" }}>
                      <div
                        style={{
                          width: "150px",
                          backgroundColor: "grey",
                          color: "white",
                          padding: "10px",
                          borderRadius: "5px",
                          textAlign: "center",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FileOpenRounded />
                        &nbsp;Subir Archivo
                      </div>
                    </label>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}

              <div>
                {selectedFiles.length > 0 && (
                  <div style={{ marginTop: "10px" }}>
                    <Typography variant="h6" align="left">
                      Archivos Seleccionados: {selectedFiles.length}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                      }}
                    >
                      {selectedFiles.map((file, index) => {
                        console.log("map files", file);
                        return (
                          <div
                            key={index}
                            style={{
                              width: "330px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <a href={file.urlFile}>{file.fileName}</a>
                            {/* {file.type.includes("image") ? (
                            <div>
                              <img
                                src={URL.createObjectURL(file)}
                                alt="Selected"
                                style={{
                                  width: "150px",
                                  height: "150px",
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                          ) : (
                            <p>{file.name}</p>
                          )} */}
                            {(!modalView && !sendService) ? (
                              <div>
                                <Button
                                  onClick={() => removeFile(index)}
                                  variant="contained"
                                  color="error"
                                >
                                  <DeleteOutline />
                                </Button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {!modalView ? (
                <Button
                  disabled={!enabled || sendService}
                  onClick={() => onHandleClick()}
                  variant="contained"
                  fullWidth
                  style={{ height: "50px" }}
                  startIcon={<SaveOutlined />}
                >
                  {data === null ? sendService?"Agregando":"Agregar" : sendService?"Actualizando":"Actualizar"}
                </Button>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
