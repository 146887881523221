import { Button } from '@material-ui/core';
import { SaveOutlined } from '@material-ui/icons';
import { Box, Grid, Modal, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { addTypeProjectService, updateTypeProjectService } from '../../../services/type_project_service';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const TypesProjectModal = ({ open, handleClose, data, getData }) => {


    const [name, setName] = useState('');
    const [enabled, setEnabled] = useState(false);

    const onHandleClick = async () => {
        var response;
        var payload = { valor: name, campo: name }
        if (data !== null) {
            response = await updateTypeProjectService(data?.id, payload);
        } else {
            response = await addTypeProjectService(payload);
        }
        handleClose();
        if (response?.statusCode === 201 || response?.statusCode === 200) {
            await Swal.fire("OK", data === null ? 'Creado correctamente' : 'Actualizado correctamente', "success");
            getData();
        } else {
            Swal.fire("Error", response?.message, "error");
        }
    }



    useEffect(() => {
        if (data !== null) {
            setName(data?.valor);
        }
    }, [data])

    useEffect(() => {
        if (name !== '') {
            setEnabled(true)
        } else {
            setEnabled(false);
        }
    }, [name])

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography variant="h5" style={{ marginBottom: '20px', fontWeight: 'revert' }}>
                        {data === null ? 'Agregar' : 'Editar'} Tipo de Proyecto
                    </Typography>
                    <Grid container spacing={4} style={{}} >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                            <TextField
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                fullWidth
                                id="nombre"
                                label="Nombre"
                                type='text'
                                autoComplete='off'
                            />

                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                            <Button
                                disabled={!enabled}
                                onClick={() => onHandleClick()}
                                variant='contained' fullWidth style={{ height: '50px' }} startIcon={<SaveOutlined />}>
                                {data === null ? 'Agregar' : 'Actualizar'}
                            </Button>
                        </Grid>
                    </Grid>


                </Box>
            </Modal>
        </>
    )
}
