import axios from "axios";
import { BASEURL } from "./constantes";

const getTypeProjectsService = async () => {
    try {
        const response = await axios.get(BASEURL + "/projects/type");
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};


const addTypeProjectService = async (data) => {
    try {
        const response = await axios.post(BASEURL + "/projects/type", data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

const updateTypeProjectService = async (id, data) => {
    try {
        const response = await axios.patch(BASEURL + "/projects/type/" + id, data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};


const deleteTypeProjectService = async (id) => {
    try {
        const response = await axios.delete(BASEURL + "/projects/type/" + id);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

export {
    addTypeProjectService,
    getTypeProjectsService,
    updateTypeProjectService,
    deleteTypeProjectService,
};
