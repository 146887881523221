import axios from "axios";
import { BASEURL } from "./constantes";

const getProjectsService = async () => {
    try {
        const response = await axios.get(BASEURL + "/projects");
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};


const addProjectService = async (data) => {
    try {
        const response = await axios.post(BASEURL + "/projects", data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

const updateProjectService = async (id, data) => {
    try {
        const response = await axios.patch(BASEURL + "/projects/" + id, data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};


const deleteProjectService = async (id) => {
    try {
        const response = await axios.delete(BASEURL + "/projects/" + id);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

export {
    addProjectService,
    getProjectsService,
    updateProjectService,
    deleteProjectService
};
