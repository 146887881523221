import { Button } from '@material-ui/core';
import { SaveOutlined } from '@material-ui/icons';
import { Autocomplete, Box, Grid, Modal, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { addUserProjectService, updateUserProjectService } from '../../services/user_project_service';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const UserProjectModal = ({ open, handleClose, data, getData, users, projects }) => {


    const [startDatetime, setStartDatetime] = useState('');
    const [endDatetime, setEndDatetime] = useState('');
    const [project, setProject] = useState('');
    const [user, setUser] = useState('');

    const [enabled, setEnabled] = useState(false);

    const onHandleClick = async () => {
        var response;
        var payload = { startDate: startDatetime, finalDate: endDatetime }

        if (user?.id) {
            payload.userId = user?.id;
        }


        if (project?.id) {
            payload.projectId = project?.id;
        }

        if (data !== null) {
            response = await updateUserProjectService(data?.id, payload);
        } else {
            response = await addUserProjectService(payload);
        }
        handleClose();
        if (response?.statusCode === 201 || response?.statusCode === 200) {
            await Swal.fire("OK", data === null ? 'Creado correctamente' : 'Actualizado correctamente', "success");
            getData();
        } else {
            Swal.fire("Error", response?.message, "error");
        }
    }


    const onChangeProject = (data) => {
        if (data) {
            setProject(data);
        } else {
            setProject('');
        }
    }

    const onChangeUser = (data) => {
        if (data) {
            setUser(data);
        } else {
            setUser('');
        }
    }



    useEffect(() => {
        if (data !== null) {
            setStartDatetime(data?.startDate);
            setEndDatetime(data?.finalDate);
            setProject(data?.project?.name);
            setUser(data?.user?.nombre);
        }
    }, [data])

    useEffect(() => {
        if (startDatetime !== '' && endDatetime !== '' && project !== '' && user !== '') {
            setEnabled(true)
        } else {
            setEnabled(false);
        }
    }, [startDatetime, endDatetime, project, user])

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography variant="h5" style={{ marginBottom: '20px', fontWeight: 'revert' }}>
                        {data === null ? 'Agregar' : 'Editar'} Proyecto de Usuario
                    </Typography>
                    <Grid container spacing={4} style={{}} >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                            <TextField
                                value={startDatetime}
                                onChange={(e) => setStartDatetime(e.target.value)}
                                fullWidth
                                id="startDatetime"
                                label="Fecha de Inicio"
                                type='text'
                                autoComplete='off'
                            />

                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                            <TextField
                                value={endDatetime}
                                onChange={(e) => setEndDatetime(e.target.value)}
                                fullWidth
                                id="endDatetime"
                                label="Fecha de Fin"
                                type='text'
                                autoComplete='off'
                            />

                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Autocomplete
                                value={project}
                                fullWidth
                                id="combo-box-type-projects"
                                options={projects}
                                onChange={(_, value) => {
                                    onChangeProject(value);
                                }}
                                renderInput={(params) => <TextField {...params} id='projectId' label="Proyectos" />}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Autocomplete
                                value={user}
                                fullWidth
                                id="combo-box-type-users"
                                options={users}
                                onChange={(_, value) => {
                                    onChangeUser(value);
                                }}
                                renderInput={(params) => <TextField {...params} id='userId' label="Usuario" />}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                            <Button
                                disabled={!enabled}
                                onClick={() => onHandleClick()}
                                variant='contained' fullWidth style={{ height: '50px' }} startIcon={<SaveOutlined />}>
                                {data === null ? 'Agregar' : 'Actualizar'}
                            </Button>
                        </Grid>
                    </Grid>


                </Box>
            </Modal>
        </>
    )
}
