import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Container, } from '@mui/material';


export const NoMatch = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1)
    };

    return (
        <Container style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        }}>
            <Typography variant="h1" align="center">
                404 - Página no encontrada
            </Typography>
            <Typography variant="body1" align="center">
                Lo sentimos, la página que buscas no existe.
            </Typography>

            <div style={{
                marginTop: '20px',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
            }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleGoBack}
                >
                    Volver Atrás
                </Button>
            </div>
        </Container>
    );
};

