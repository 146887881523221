import React from 'react'
import { Navigate } from 'react-router-dom';


// Si hay usuario logueado, enviarme al home 
export const PublicRoute = ({ user, children }) => {
    if (user) {
        return (
            //<Navigate to="/home" replace />
            <Navigate to="/incidence" replace />
        );
    }

    return children;
}
