import { Button } from "@material-ui/core";
import { SaveOutlined } from "@material-ui/icons";
import ModalClose from "@mui/joy/ModalClose";
import {
    Autocomplete,
    Box,
    Grid,
    Modal,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { addUserService, updateUserService } from "../../services/user_service";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const UserModal = ({ open, handleClose, data, getData, roles }) => {
  const [name, setName] = useState("");
  const [surnames, setSurnames] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [document, setDocument] = useState("");
  const [rol, setRol] = useState("");
  const [enabled, setEnabled] = useState(false);

  const onHandleClick = async () => {
    var response;
    var payload = {
      nombre: name,
      apellidos: surnames,
      email,
      password,
      document,
    };

    if (rol?.id) {
      payload.rolId = rol?.id;
    }

    if (data !== null) {
      response = await updateUserService(data?.id, payload);
    } else {
      response = await addUserService(payload);
    }
    handleClose();
    if (response?.statusCode === 201 || response?.statusCode === 200) {
      await Swal.fire(
        "OK",
        data === null ? "Creado correctamente" : "Actualizado correctamente",
        "success"
      );
      getData();
    } else {
      Swal.fire("Error", response?.message, "error");
    }
  };

  const onChangeRoles = (data) => {
    if (data) {
      setRol(data);
    } else {
      setRol("");
    }
  };

  useEffect(() => {
    if (data !== null) {
      setName(data?.nombre);
      setSurnames(data?.apellidos);
      setEmail(data?.email);
      setDocument(data?.document);
      setPassword(data?.password);
      setRol(data?.rol?.valor);
    }
  }, [data]);

  useEffect(() => {
    if (
      name !== "" &&
      email !== "" &&
      document !== "" &&
      rol !== "" &&
      password !== ""
    ) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, [name, email, document, rol, password]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ display: "flex" }}>
            <ModalClose variant="plain" sx={{ m: 1 }} onClick={handleClose} />
            <Typography
              variant="h5"
              style={{ marginBottom: "20px", fontWeight: "revert" }}
            >
              {data === null ? "Agregar" : "Editar"} Usuario
            </Typography>
          </div>
          <Grid container spacing={4} style={{}}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                id="nombre"
                label="Nombre"
                type="text"
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                value={surnames}
                onChange={(e) => setSurnames(e.target.value)}
                fullWidth
                id="apellidos"
                label="apellidos"
                type="text"
                autoComplete="off"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                id="email"
                label="Email"
                type="email"
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                value={data !== null ? null : password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                id="password"
                label="Contraseña"
                type="password"
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                value={document}
                onChange={(e) => setDocument(e.target.value)}
                fullWidth
                id="document"
                label="Documento"
                type="text"
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Autocomplete
                value={rol}
                fullWidth
                id="combo-box-type-projects"
                options={roles}
                onChange={(_, value) => {
                  onChangeRoles(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} id="rolId" label="Tipo de Rol" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button
                disabled={!enabled}
                onClick={() => onHandleClick()}
                variant="contained"
                fullWidth
                style={{ height: "50px" }}
                startIcon={<SaveOutlined />}
              >
                {data === null ? "Agregar" : "Actualizar"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
