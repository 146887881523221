
import { Search } from '@material-ui/icons';
import { DownloadDoneOutlined, SaveOutlined } from '@mui/icons-material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { utils, writeFile } from "xlsx";
import { getIncidencesAnalistaService, getIncidencesService, getIncidencesSupervisorService, getStatusIncidencesService } from '../../services/incidence_service';
import { getProjectsService } from '../../services/project_service';
import { deleteReportService, getReportsAnalistaService, getReportsService, getReportsSupervisorService } from '../../services/report_service';
import { getUserService } from '../../services/user_service';
import { Spacer } from '../../utils/Spacer';
import { StripedDataGrid } from '../../utils/StripedDataGrid';
import { CustomDrawer } from '../Drawer/CustomDrawer';
import { ReportModal } from './ReportModal';


export const ReportPage = () => {
    const profile = JSON.parse(localStorage.getItem('user'))
    const [search, setSearch] = useState('');

    const [reports, setReports] = useState([]);
    const [filteredReports, setFilteredReports] = useState([]);
    const [incidences, setIncidences] = useState([]);
    const [projects, setProjects] = useState([]);
    const [statusIncidences, setStatusIncidences] = useState([]);
    const [users, setUsers] = useState([]);

    const [dataEdited, setDataEdited] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const [reportsDownload, setReportsDownload] = useState([]);
    const [modalView, setModalView] = useState(false)



    const onChangeSearch = (e) => {
        const term = e.target.value;
        setSearch(term);
        if (term.trim() === '') {
            setFilteredReports(reports);
        } else {
            const filtered = reports.filter(report =>
                report.fecha.toLowerCase().includes(term.toLowerCase()) ||
                report.project?.name.toLowerCase().includes(term.toLowerCase()) ||
                //report.user?.nombre.toLowerCase().includes(term.toLowerCase()) ||
                report.incident?.title.toLowerCase().includes(term.toLowerCase()) ||
                report.incidentStatus?.valor.toLowerCase().includes(term.toLowerCase())||
                report.responsible?.nombre.toLowerCase().includes(term.toLowerCase())||
                report.comment.toLowerCase().includes(term.toLowerCase())
            );
            setFilteredReports(filtered);
        }
    }


    useEffect(() => {
        fetchGetReports();
        fetchGetIncidences();
        fetchGetProjects();
        fetchGetStatusIncidences();
        fetchGetUsers();
    }, [])

    const fetchGetReports = async () => {
        var response;
        if (profile?.rolName === "Supervisor") {
            response = await getReportsSupervisorService(profile?.id);
        } else if (profile?.rolName === "Analista") {
            response = await getReportsAnalistaService(profile?.id);
        } else {
            response = await getReportsService();
        }
        response = response.map(report =>{
            return{
                ...report,
                archivos: report.filestext ? report.filestext.split("|||").length: 0
            }
        })
        setReports(response);
        setFilteredReports(response);
    }


    const fetchGetIncidences = async () => {
        var response;
        if (profile?.rolName === "Supervisor") {
            response = await getIncidencesSupervisorService(profile?.id);
        } else if (profile?.rolName === "Analista") {
            response = await getIncidencesAnalistaService(profile?.id);
        } else {
            response = await getIncidencesService();
        }
        const responseParsed = response.map(e => {
            return {
                'id': e?.id,
                'label': e?.title,
                'value': e?.id,
            }
        })
        setIncidences(responseParsed);
    }

    const fetchGetProjects = async () => {
        const response = await getProjectsService();
        const responseParsed = response.map(e => {
            return {
                'id': e?.id,
                'label': e?.name,
                'value': e?.id,
            }
        })
        setProjects(responseParsed);
    }

    const fetchGetStatusIncidences = async () => {
        const response = await getStatusIncidencesService();
        const responseParsed = response.map(e => {
            return {
                'id': e?.id,
                'label': e?.valor,
                'value': e?.id,
            }
        })
        setStatusIncidences(responseParsed);
    }

    const fetchGetUsers = async () => {
        const response = await getUserService();
        const responseParsed = response.map(e => {
            return {
                'id': e?.id,
                'label': e?.nombre,
                'value': e?.id,
            }
        })
        setUsers(responseParsed);
    }


    const onHandleAddReport = () => {
        setDataEdited(null);
        setShowModal(true);
        setModalView(false);
    }

    const onHandleEditReport = (data,modalView=false) => {
        setDataEdited(data);
        setShowModal(true);
        setModalView(modalView);
    }

    const onHandleDeleteReport = async (id) => {
        const response = await deleteReportService(id);
        if (response?.statusCode === 200) {
            Swal.fire("OK", 'Eliminado correctamente', "success");
        } else {
            Swal.fire("Error", "Ocurrió un error", "error");
        }
        fetchGetReports();
    }


    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'fecha', headerName: 'Fecha', width: 100 },
        {
            field: 'project', headerName: 'Proyecto', width: 200, renderCell: (params) => {
                return <>
                    <div>{params?.row?.project?.name}</div>
                </>
            }
        },
        {
            field: 'user', headerName: 'Usuario', width: 150, renderCell: (params) => {
                return <>
                    <div>{profile?.rolName !== "Analista" ? params?.row?.responsible?.nombre : params?.row?.user?.nombre}</div>
                </>
            }
        },
        {
            field: 'incident', headerName: 'Incidencia', width: 200, renderCell: (params) => {
                return <>
                    <div>{params?.row?.incident?.title}</div>
                </>
            }
        },
        {
            field: 'comment', headerName: 'Comentario', width: 200, renderCell: (params) => {
                return <>
                    <div>{params?.row?.comment}</div>
                </>
            }
        },
        {
            field: 'incidentStatus',
            headerName: 'Estado',
            width: 200,
            renderCell: (params) => {
                return <>
                    <div>{params?.row?.incidentStatus?.valor}</div>
                </>
            }
        },
        { field: 'archivos', headerName: 'archivos', width: 150 },

        {
            field: 'acciones',
            headerName: 'Acciones',
            width: 300,
            renderCell: (params) => (
                <>
                    {
                        profile?.rolName !== "Supervisor" && <>
                            <Button startIcon={<EditOutlinedIcon />} onClick={() => onHandleEditReport(params?.row)} variant='contained' color='success'>
                                Editar
                            </Button>
                            <Spacer />
                            <Button startIcon={<DeleteOutlineOutlinedIcon />} onClick={() => { onHandleDeleteReport(params?.row?.id) }} variant='contained' color='error'>
                                Eliminar
                            </Button>
                            <Spacer />
                            <Button startIcon={<VisibilityIcon />} onClick={() => { onHandleEditReport(params?.row,true) }} variant='contained' color='success'>
                                Ver
                            </Button></>
                    }

                </>
            ),
        },
    ];

    const handleDownloadExcel = async () => {
        try {
            var response;
            if (profile?.rolName === "Supervisor") {
                response = await getReportsSupervisorService(profile?.id);
            } else if (profile?.rolName === "Analista") {
                response = await getReportsAnalistaService(profile?.id);
            } else {
                response = await getReportsService();
            }
            setReportsDownload(response);
        } catch (error) {
            console.error(error);
            Swal.fire("Error", error.message, "error");
        }
    }


    useEffect(() => {
        if (reportsDownload.length === 0) return;
        const wb = utils.book_new();
        const ws = utils.table_to_sheet(document.getElementById("table-to-export"));
        utils.book_append_sheet(wb, ws, `Reportes`);
        writeFile(wb, `Reporte.xlsx`);
    }, [reportsDownload]);


    return (
        <div style={{ height: '100vh', backgroundColor: '#f7f7f7' }}>
            <CustomDrawer>
                <Typography variant="h5" style={{ marginBottom: '30px', fontWeight: 'revert' }}>
                    Reportes
                </Typography>

                <Button startIcon={<DownloadDoneOutlined />} onClick={() => handleDownloadExcel()} variant='outlined' color='success'>Descargar Excel</Button>


                <Box style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '5px', border: '1px solid #ccc', marginTop: '20px' }}>

                    <div style={{ display: 'flex' }}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton style={{ padding: '0px' }}>
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            value={search}
                            onChange={onChangeSearch}
                            fullWidth
                            id="search"
                            label="Buscar"
                            type='text'
                            autoComplete='off'


                        />
                        {
                            profile?.rolName !== "Supervisor" && <Button startIcon={<SaveOutlined />} variant='contained' style={{ marginLeft: '10px' }} onClick={() => onHandleAddReport()}>
                                Agregar
                            </Button>
                        }

                    </div>


                    <div style={{ height: 400, width: '100%', marginTop: '20px' }}>
                        <StripedDataGrid
                            hideFooterSelectedRowCount
                            rowSelection={false}
                            rows={filteredReports}
                            columns={columns}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : ''
                            }
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}

                            pageSizeOptions={[5, 10]}
                        />
                    </div>

                    <div style={{ display: "none" }}>
                        <table
                            className="table table-bordered table-striped"
                            id="table-to-export"
                        >
                            
                            <thead>
                                <tr>
                                    <th scope="col">Id</th>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Proyecto</th>
                                    <th scope="col">Usuario</th>
                                    <th scope="col">Incidencia</th>
                                    <th scope="col">Comentarios</th>
                                    <th scope="col">Archivos</th>
                                    <th scope="col">Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reportsDownload.map((c, i) => {
                                    return (
                                        <tr key={i}>
                                            <td style={{ width: "15%" }}>
                                                {c?.id}
                                            </td>
                                            <td style={{ width: "30%" }}>
                                                {c?.fecha}
                                            </td>
                                            <td style={{ width: "10%" }}>
                                                {c?.project?.name}
                                            </td>
                                            <td style={{ width: "20%" }}>
                                                {profile?.rolName !== "Analista" ? c?.responsible?.nombre : c?.user?.nombre}
                                            </td>
                                            <td style={{ width: "20%" }}>
                                                {c?.incident?.title}
                                            </td>
                                            <td style={{ width: "20%" }}>
                                                {c?.comment}
                                            </td>
                                            <td style={{ width: "20%" }}>
                                                {c?.filestext? c.filestext.split("|||").map(e=>{
                                                    const f = e.split("||");
                                                    return f[0]
                                                }).join(" , "): ""}
                                            </td>
                                            <td style={{ width: "20%" }}>
                                                {c?.incidentStatus?.valor}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>

                </Box>
            </CustomDrawer>

            {showModal &&
                <ReportModal
                    open={showModal}
                    handleClose={() => setShowModal(false)}
                    data={dataEdited}
                    getData={() => {
                        setShowModal(false)
                        fetchGetReports();
                    }}
                    incidences={incidences}
                    projects={projects}
                    statusIncidences={statusIncidences}
                    users={users}
                    modalView={modalView}
                />}
        </div>
    );
}