import { Button } from '@material-ui/core';
import { SaveOutlined } from '@material-ui/icons';
import { Autocomplete, Box, Grid, Modal, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { addProjectService, updateProjectService } from '../../services/project_service';
import Swal from 'sweetalert2';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const ProjectModal = ({ open, handleClose, data, getData, companies, typeProjects }) => {

    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [typeProject, setTypeProject] = useState('');
    const [enabled, setEnabled] = useState(false);

    const onHandleClick = async () => {
        var response;
        var payload = { name }

        if (company?.id) {
            payload.companyId = company?.id;
        }

        if (typeProject?.id) {
            payload.typeProjectId = typeProject?.id;
        }

        if (data !== null) {
            response = await updateProjectService(data?.id, payload);
        } else {
            response = await addProjectService(payload);
        }
        handleClose();
        if (response?.statusCode === 201 || response?.statusCode === 200) {
            await Swal.fire("OK", data === null ? 'Creado correctamente' : 'Actualizado correctamente', "success");
            getData();
        } else {
            Swal.fire("Error", response?.message, "error");
        }
    }


    const onChangeCompany = (data) => {
        if (data) {
            setCompany(data);
        } else {
            setCompany('');
        }
    }

    const onChangeTypeProject = (data) => {
        if (data) {
            setTypeProject(data);
        } else {
            setTypeProject('');
        }
    }


    useEffect(() => {
        if (data !== null) {
            setName(data?.name);
            setCompany(data?.company?.businessName);
            setTypeProject(data?.typeProject?.campo);
        }
    }, [data])

    useEffect(() => {
        if (name !== '' && company !== '' && typeProject !== '') {
            setEnabled(true)
        } else {
            setEnabled(false);
        }
    }, [name, company, typeProject])



    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography variant="h5" style={{ marginBottom: '20px', fontWeight: 'revert' }}>
                        {data === null ? 'Agregar' : 'Editar'} Proyecto
                    </Typography>
                    <Grid container spacing={4} style={{}} >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                            <Autocomplete
                                value={company}
                                fullWidth
                                id="combo-box-type-company"
                                options={companies}
                                onChange={(_, value) => {
                                    onChangeCompany(value);
                                }}
                                renderInput={(params) => <TextField {...params} id='companyId' label="Empresa" />}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Autocomplete
                                value={typeProject}
                                fullWidth
                                id="combo-box-type-projects"
                                options={typeProjects}
                                onChange={(_, value) => {
                                    onChangeTypeProject(value);
                                }}
                                renderInput={(params) => <TextField {...params} id='typeProject' label="Tipo de Proyecto" />}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                            <TextField
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                fullWidth
                                id="name"
                                label="Nombre"
                                type='text'
                                autoComplete='off'
                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                            <Button
                                disabled={!enabled}
                                onClick={() => onHandleClick()}
                                variant='contained' fullWidth style={{ height: '50px' }} startIcon={<SaveOutlined />}>
                                {data === null ? 'Agregar' : 'Actualizar'}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal >
        </>
    )
}
