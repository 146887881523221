
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { ArrowBack, Search } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { Spacer } from '../../../utils/Spacer';
import Swal from 'sweetalert2';
import { StripedDataGrid } from '../../../utils/StripedDataGrid';
import { CustomDrawer } from '../../Drawer/CustomDrawer';
import { useNavigate } from 'react-router-dom';
import { TypesIncidenceModal } from './TypesIncidenceModal';
import { getTypesIncidencesService } from '../../../services/incidence_service';
import { deleteTypeIncidenceService } from '../../../services/type_incidence_service';
import { SaveOutlined } from '@mui/icons-material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

export const TypesIncidencePage = () => {

    const [search, setSearch] = useState('');
    const [incidences, setIncidences] = useState([]);
    const [filteredTypeIncidences, setFilteredTypeIncidences] = useState([]);

    const [dataEdited, setDataEdited] = useState(null);
    const [showModal, setShowModal] = useState(false);


    const navigate = useNavigate();


    const onChangeSearch = (e) => {
        const term = e.target.value;
        setSearch(term);
        if (term.trim() === '') {
            setFilteredTypeIncidences(incidences);
        } else {
            const filtered = incidences.filter(rol =>
                rol.valor?.toLowerCase().includes(term.toLowerCase()) ||
                rol.campo?.toLowerCase().includes(term.toLowerCase())
            );
            setFilteredTypeIncidences(filtered);
        }
    }


    useEffect(() => {
        fetchGetTypesIncidence();
    }, [])

    const fetchGetTypesIncidence = async () => {
        const response = await getTypesIncidencesService();
        setIncidences(response);
        setFilteredTypeIncidences(response);
    }

    const onHandleAddTypeIncidence = () => {
        setDataEdited(null);
        setShowModal(true);
    }

    const onHandleEditTypeIncidence = (data) => {
        setDataEdited(data);
        setShowModal(true);
    }

    const onHandleDeleteTypeIncidence = async (id) => {
        const response = await deleteTypeIncidenceService(id);
        if (response?.statusCode === 200) {
            Swal.fire("OK", 'Eliminado correctamente', "success");
        } else {
            Swal.fire("Error", "Ocurrió un error", "error");
        }
        fetchGetTypesIncidence();
    }



    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'valor', headerName: 'Nombre', width: 200 },
        {
            field: 'acciones',
            headerName: 'Acciones',
            width: 260,
            renderCell: (params) => (
                <>
                    <Button startIcon={<EditOutlinedIcon />} onClick={() => onHandleEditTypeIncidence(params?.row)} variant='contained' color='success'>
                        Editar
                    </Button>
                    <Spacer />
                    <Button startIcon={<DeleteOutlineOutlinedIcon />} onClick={() => { onHandleDeleteTypeIncidence(params?.row?.id) }} variant='contained' color='error'>
                        Eliminar
                    </Button>
                </>
            ),
        },
    ];

    const handleGoBack = () => {
        navigate(-1)
    };


    return (
        <div style={{ height: '100vh', backgroundColor: '#f7f7f7' }}>
            <CustomDrawer>
                <Button startIcon={<ArrowBack />} color='error' variant='outlined' onClick={handleGoBack}>
                    Volver
                </Button>
                <Typography variant="h5" style={{ marginBottom: '30px', fontWeight: 'revert', marginTop: '20px' }}>
                    Tipos de Incidencia
                </Typography>

                <Box style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '5px', border: '1px solid #ccc' }}>

                    <div style={{ display: 'flex' }}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton style={{ padding: '0px' }}>
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            value={search}
                            onChange={onChangeSearch}
                            fullWidth
                            id="search"
                            label="Buscar"
                            type='text'
                            autoComplete='off'


                        />
                        <Button startIcon={<SaveOutlined />} variant='contained' style={{ marginLeft: '10px' }} onClick={() => onHandleAddTypeIncidence()}>
                            Agregar
                        </Button>
                    </div>


                    <div style={{ height: 400, width: '100%', marginTop: '20px' }}>
                        <StripedDataGrid
                            hideFooterSelectedRowCount
                            rowSelection={false}
                            rows={filteredTypeIncidences}
                            columns={columns}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : ''
                            }
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}

                            pageSizeOptions={[5, 10]}
                        />
                    </div>
                </Box>
            </CustomDrawer>

            {showModal &&
                <TypesIncidenceModal
                    open={showModal}
                    handleClose={() => setShowModal(false)}
                    data={dataEdited}
                    getData={() => {
                        setShowModal(false)
                        fetchGetTypesIncidence();
                    }}
                />}
        </div>
    );
}