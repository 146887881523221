import axios from "axios";
import { BASEURL } from "./constantes";



const getReportsSupervisorService = async (id) => {
    try {
        const response = await axios.get(BASEURL + "/reports/user-supervisor/" + id);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

const getReportsAnalistaService = async (id) => {
    try {
        const response = await axios.get(BASEURL + "/reports/user-analista/" + id);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

const getReportsService = async () => {
    try {
        const response = await axios.get(BASEURL + "/reports");
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};


const addReportService = async (data) => {
    try {
        const response = await axios.post(BASEURL + "/reports", data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

const updateReportService = async (id, data) => {
    try {
        const response = await axios.patch(BASEURL + "/reports/" + id, data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};


const deleteReportService = async (id) => {
    try {
        const response = await axios.delete(BASEURL + "/reports/" + id);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

const getReportsDownload = async () => {
    try {
        const response = await axios.get(BASEURL + "/reports/download");
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
}

export {
    getReportsService,
    addReportService,
    updateReportService,
    deleteReportService,
    getReportsDownload,
    getReportsSupervisorService,
    getReportsAnalistaService,
};
