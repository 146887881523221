import axios from "axios";
import { BASEURL } from "./constantes";


const getIncidencesSupervisorService = async (id) => {
    try {
        const response = await axios.get(BASEURL + "/incidence/user-supervisor/" + id);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

const getIncidencesAnalistaService = async (id) => {
    try {
        const response = await axios.get(BASEURL + "/incidence/user-analista/" + id);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

const getIncidencesService = async () => {
    try {
        const response = await axios.get(BASEURL + "/incidence");
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

const getIncidenceByIdService = async (id) => {
    try {
        const response = await axios.get(BASEURL + "/incidence/" + id);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

const getTypesIncidencesService = async () => {
    try {
        const response = await axios.get(BASEURL + "/incidence/types");
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

const getStatusIncidencesService = async () => {
    try {
        const response = await axios.get(BASEURL + "/incidence/status");
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};


const addIncidenceService = async (data) => {
    try {
        const response = await axios.post(BASEURL + "/incidence", data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

const updateIncidenceService = async (id, data) => {
    try {
        const response = await axios.patch(BASEURL + "/incidence/" + id, data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};


const deleteIncidenceService = async (id) => {
    try {
        const response = await axios.delete(BASEURL + "/incidence/" + id);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

export {
    addIncidenceService,

    getIncidencesService,
    getTypesIncidencesService,
    getStatusIncidencesService,

    updateIncidenceService,
    deleteIncidenceService,

    getIncidencesSupervisorService,
    getIncidencesAnalistaService,

    getIncidenceByIdService,
};
