import React, { useState } from 'react'
import { CustomDrawer } from '../Drawer/CustomDrawer'
import { Autocomplete, Box, Button, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { DeleteOutline, FileOpenRounded, SaveOutlined, Search } from '@mui/icons-material';



const listProjects = [
    { label: 'Proyecto 1', value: 'proyecto 1' },
    { label: 'Proyecto 2', value: 'proyecto 2' },
    { label: 'Proyecto 3', value: 'proyecto 3' },
    { label: 'Proyecto 4', value: 'proyecto 4' },
];
const listCodes = [
    { label: '001', value: '001' },
    { label: '002', value: '002' },
    { label: '003', value: '003' },
    { label: '004', value: '004' },
];
const listStatus = [
    { label: 'Iniciado', value: 'iniciado' },
    { label: 'Finalizado', value: 'finalizado' },
    { label: 'Atrazado', value: 'atrazado' },
];
const listNotify = [
    { label: 'Si', value: 'si' },
    { label: 'No', value: 'no' },
];

const listService = [
    { label: 'Revicion', value: 'revicion' },
    { label: 'Solucion', value: 'solucion' },
];

const listVisitCamp = [
    { label: 'Si', value: 'si' },
    { label: 'No', value: 'no' },
    { label: 'Repsol', value: 'repsol' },
]

const listGroupFuncionaly = [
    { label: 'Si', value: 'si' },
    { label: 'No', value: 'no' },
];

const listEspecialist = [
    { label: 'Jose Galvez', value: 'jose galvez' },
    { label: 'PetroPeru', value: 'petroperu' },
];




export const RegisterPage = () => {

    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileChange = (e) => {
        const files = e.target.files;

        if (files.length > 0 && selectedFiles.length <= 3) {
            setSelectedFiles([...selectedFiles, ...files]);
        }
    };

    const removeFile = (index) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(index, 1);
        setSelectedFiles(newFiles);
    };
    return (
        <div style={{ height: '100vh', backgroundColor: '#f7f7f7' }}>

            <CustomDrawer>
                <div>
                    <Typography variant="h5" align="start" style={{ marginBottom: '30px', fontWeight: 'revert' }}>
                        Registrar
                    </Typography>
                    <Box style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '5px', border: '1px solid #ccc' }}>

                        <Grid container spacing={4} style={{}} >
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    id="combo-box-type-proyect"
                                    options={listProjects}
                                    onChange={(_, data) => {
                                        //onChangeRolType(data?.value);
                                    }}
                                    renderInput={(params) => <TextField {...params} id='rolType' label="Proyecto" />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    id="combo-box-type-code"
                                    options={listCodes}
                                    onChange={(_, data) => {
                                        //onChangeRolType(data?.value);
                                    }}
                                    renderInput={(params) => <TextField {...params} id='rolType' label="Código" />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
                                <Autocomplete
                                    fullWidth
                                    id="combo-box-type-status"
                                    options={listStatus}
                                    onChange={(_, data) => {
                                        //onChangeRolType(data?.value);
                                    }}
                                    renderInput={(params) => <TextField {...params} id='rolType' label="Estado" />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
                                <Autocomplete
                                    fullWidth
                                    id="combo-box-type-notify"
                                    options={listNotify}
                                    onChange={(_, data) => {
                                        //onChangeRolType(data?.value);
                                    }}
                                    renderInput={(params) => <TextField {...params} id='rolType' label="Notificar" />}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                {/*  <Autocomplete
                                    fullWidth
                                    disablePortal
                                    id="combo-box-type-proyect"
                                    //options={listRolType}
                                    options={[]}
                                    onChange={(_, data) => {
                                        //onChangeRolType(data?.value);
                                    }}
                                    renderInput={(params) => <TextField {...params} id='rolType' label="Responsable" />}
                                /> */}

                                <TextField
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton style={{ padding: '0px' }}>
                                                    <Search />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth
                                    id="responsability"
                                    label="Responsable"
                                    type='text'
                                    autoComplete='off'
                                />

                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                {/* <Autocomplete
                                    fullWidth
                                    disablePortal
                                    id="combo-box-type-proyect"
                                    //options={listRolType}
                                    options={[]}
                                    onChange={(_, data) => {
                                        //onChangeRolType(data?.value);
                                    }}
                                    renderInput={(params) => <TextField {...params} id='rolType' label="Categoría" />}
                                /> */}
                                <TextField
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton style={{ padding: '0px' }}>
                                                    <Search />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth
                                    id="category"
                                    label="Categoría"
                                    type='text'
                                    autoComplete='off'
                                />

                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                {/*  <Autocomplete
                                    fullWidth
                                    id="combo-box-type-proyect"
                                    //options={listRolType}
                                    options={[]}
                                    onChange={(_, data) => {
                                        //onChangeRolType(data?.value);
                                    }}
                                    renderInput={(params) => <TextField {...params} id='rolType' label="Compañia" />}
                                /> */}

                                <TextField
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton style={{ padding: '0px' }}>
                                                    <Search />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth
                                    id="company"
                                    label="Compañia"
                                    type='text'
                                    autoComplete='off'
                                />

                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                <Autocomplete
                                    fullWidth
                                    id="combo-box-type-service"
                                    options={listService}
                                    onChange={(_, data) => {
                                        //onChangeRolType(data?.value);
                                    }}
                                    renderInput={(params) => <TextField {...params} id='rolType' label="Servicio" />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                <Autocomplete
                                    fullWidth
                                    id="combo-box-type-visit-camp"
                                    options={listVisitCamp}
                                    onChange={(_, data) => {
                                        //onChangeRolType(data?.value);
                                    }}
                                    renderInput={(params) => <TextField {...params} id='rolType' label="Visita de Campo" />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                {/* <Autocomplete
                                    fullWidth
                                    id="combo-box-type-proyect"
                                    //options={listRolType}
                                    options={[]}
                                    onChange={(_, data) => {
                                        //onChangeRolType(data?.value);
                                    }}
                                    renderInput={(params) => <TextField {...params} id='rolType' label="Localización" />}
                                /> */}
                                <TextField
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton style={{ padding: '0px' }}>
                                                    <Search />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth
                                    id="location"
                                    label="Localización"
                                    type='text'
                                    autoComplete='off'
                                />


                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                <Autocomplete
                                    fullWidth
                                    id="combo-box-type-group-funcional"
                                    options={listGroupFuncionaly}
                                    onChange={(_, data) => {
                                        //onChangeRolType(data?.value);
                                    }}
                                    renderInput={(params) => <TextField {...params} id='rolType' label="Grupo Funcional" />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                <Autocomplete
                                    fullWidth
                                    id="combo-box-type-specialist"
                                    options={listEspecialist}
                                    onChange={(_, data) => {
                                        //onChangeRolType(data?.value);
                                    }}
                                    renderInput={(params) => <TextField {...params} id='rolType' label="Especialista" />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <TextField
                                    multiline
                                    rows={4}
                                    fullWidth
                                    id="observation"
                                    label="Observaciones"
                                    type='text'
                                    autoComplete='off'
                                />
                            </Grid>

                            <div style={{ padding: '40px' }}>
                                <div>
                                    <input
                                        type="file"
                                        accept=".pdf, .jpg, .jpeg, .png, .docx"
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                        id="fileInput"
                                        multiple
                                    />
                                    <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
                                        <div
                                            style={{
                                                width: '150px',
                                                backgroundColor: 'grey',
                                                color: 'white',
                                                padding: '10px',
                                                borderRadius: '5px',
                                                textAlign: 'center',
                                                cursor: 'pointer',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <FileOpenRounded />
                                            &nbsp;Subir Archivo
                                        </div>

                                    </label>
                                </div>




                                <div>
                                    {selectedFiles.length > 0 && (
                                        <div style={{ marginTop: '10px' }}>
                                            <Typography variant="h6" align="start">
                                                Archivos Seleccionados: {selectedFiles.length}
                                            </Typography>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    gap: '10px',
                                                }}
                                            >
                                                {selectedFiles.map((file, index) => (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            textAlign: 'center',
                                                            width: '150px',
                                                            marginBottom: '10px',
                                                            marginLeft: '20px',
                                                        }}
                                                    >
                                                        {file.type.includes('image') ? (
                                                            <div>
                                                                <p>Imagen:</p>
                                                                <img
                                                                    src={URL.createObjectURL(file)}
                                                                    alt="Selected"
                                                                    style={{
                                                                        width: '150px', height: '150px',
                                                                        objectFit: 'cover'
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <p>PDF: {file.name}</p>
                                                        )}

                                                        <Button onClick={() => removeFile(index)} variant='contained' color='error'>
                                                            <DeleteOutline />
                                                        </Button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>


                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Button variant='contained' fullWidth style={{ height: '50px' }} startIcon={<SaveOutlined />}>
                                    Guardar
                                </Button>
                            </Grid>

                        </Grid>

                    </Box>



                </div>

            </CustomDrawer >
        </div>
    )
}
