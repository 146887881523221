import axios from "axios";
import { BASEURL } from "./constantes";

const getStatusIncidenceService = async () => {
    try {
        const response = await axios.get(BASEURL + "/incidence/status");
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};


const addStatusIncidenceService = async (data) => {
    try {
        const response = await axios.post(BASEURL + "/incidence/status", data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

const updateStatusIncidenceService = async (id, data) => {
    try {
        const response = await axios.patch(BASEURL + "/incidence/status/" + id, data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};


const deleteStatusIncidenceService = async (id) => {
    try {
        const response = await axios.delete(BASEURL + "/incidence/status/" + id);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

export {
    getStatusIncidenceService,
    addStatusIncidenceService,
    updateStatusIncidenceService,
    deleteStatusIncidenceService,
};
