import axios from "axios";
import { BASEURL } from "./constantes";

const getCompanysService = async () => {
    try {
        const response = await axios.get(BASEURL + "/company");
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};


const addCompanyService = async (data) => {
    try {
        const response = await axios.post(BASEURL + "/company", data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

const updateCompanyService = async (id, data) => {
    try {
        const response = await axios.patch(BASEURL + "/company/" + id, data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};


const deleteCompanyService = async (id) => {
    try {
        const response = await axios.delete(BASEURL + "/company/" + id);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

export {
    addCompanyService,
    getCompanysService,
    updateCompanyService,
    deleteCompanyService
};
