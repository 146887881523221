
import { CustomDrawer } from '../Drawer/CustomDrawer';
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { Search } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { Spacer } from '../../utils/Spacer';
import { deleteCompanyService, getCompanysService } from '../../services/company_service';
import { CompanyModal } from './CompanyModal';
import Swal from 'sweetalert2';
import { StripedDataGrid } from '../../utils/StripedDataGrid';
import { ArrowBack, SaveOutlined } from '@mui/icons-material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useNavigate } from 'react-router-dom';

export const CompanyPage = () => {

    const navigate = useNavigate();


    const [search, setSearch] = useState('');
    const [companies, setCompanies] = useState([]);
    const [filteredCompanies, setFilteredCompanies] = useState([]);

    const [dataEdited, setDataEdited] = useState(null);
    const [showModal, setShowModal] = useState(false);


    const onChangeSearch = (e) => {
        const term = e.target.value;
        setSearch(term);
        if (term.trim() === '') {
            setFilteredCompanies(companies);
        } else {
            const filtered = companies.filter(company =>
                company.businessName.toLowerCase().includes(term.toLowerCase()) ||
                company.ruc.includes(term)
            );
            setFilteredCompanies(filtered);
        }
    }


    useEffect(() => {
        fetchGetCompany();
    }, [])

    const fetchGetCompany = async () => {
        const response = await getCompanysService();
        setCompanies(response);
        setFilteredCompanies(response);
    }

    const onHandleAddCompany = () => {
        setDataEdited(null);
        setShowModal(true);
    }

    const onHandleEditCompany = (data) => {
        setDataEdited(data);
        setShowModal(true);
    }

    const onHandleDeleteCompany = async (id) => {
        const response = await deleteCompanyService(id);
        if (response?.statusCode === 200) {
            Swal.fire("OK", 'Eliminado correctamente', "success");
        } else {
            Swal.fire("Error", "Ocurrió un error", "error");
        }
        fetchGetCompany();
    }


    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'businessName', headerName: 'Nombre', width: 200 },
        { field: 'ruc', headerName: 'RUC', width: 200 },
        {
            field: 'acciones',
            headerName: 'Acciones',
            width: 260,
            renderCell: (params) => (
                <>
                    <Button startIcon={<EditOutlinedIcon />} onClick={() => onHandleEditCompany(params?.row)} variant='contained' color='success'>
                        Editar
                    </Button>
                    <Spacer />
                    <Button startIcon={<DeleteOutlineOutlinedIcon />} onClick={() => { onHandleDeleteCompany(params?.row?.id) }} variant='contained' color='error'>
                        Eliminar
                    </Button>
                </>
            ),
        },
    ];


    const handleGoBack = () => {
        navigate(-1)
    };

    return (
        <div style={{ height: '100vh', backgroundColor: '#f7f7f7' }}>
            <CustomDrawer>
                <Button startIcon={<ArrowBack />} color='error' variant='outlined' onClick={handleGoBack}>
                    Volver
                </Button>

                <Typography variant="h5" style={{ marginBottom: '30px', fontWeight: 'revert', marginTop: '20px' }}>
                    Empresas
                </Typography>



                <Box style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '5px', border: '1px solid #ccc' }}>

                    <div style={{ display: 'flex' }}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton style={{ padding: '0px' }}>
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            value={search}
                            onChange={onChangeSearch}
                            fullWidth
                            id="search"
                            label="Buscar"
                            type='text'
                            autoComplete='off'


                        />
                        <Button
                            startIcon={<SaveOutlined />}
                            variant='contained' style={{ marginLeft: '10px' }} onClick={() => onHandleAddCompany()}
                        >
                            Agregar
                        </Button>
                    </div>


                    <div style={{ height: 400, width: '100%', marginTop: '20px' }}>
                        <StripedDataGrid
                            hideFooterSelectedRowCount
                            rowSelection={false}
                            rows={filteredCompanies}
                            columns={columns}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : ''
                            }
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}

                            pageSizeOptions={[5, 10]}
                        />
                    </div>
                </Box>

                {showModal &&
                    <CompanyModal
                        open={showModal}
                        handleClose={() => setShowModal(false)}
                        data={dataEdited}
                        getData={() => {
                            setShowModal(false)
                            fetchGetCompany();
                        }}
                    />}

            </CustomDrawer>


        </div>
    );
}