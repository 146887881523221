import { useRoutes } from "react-router-dom";
import { AuthPage } from "../components/Auth/AuthPage";
import { NoMatch } from "../components/NoMatch/NoMatch";
import { ProtectedRoute } from "./ProtectedRoute";
import { PublicRoute } from "./PublicRoute";
import { ExamplePage } from "../components/Example/ExamplePage";
import { RegisterPage } from "../components/Register/RegisterPage";
import { ReportPage } from "../components/Report/ReportPage";
import { FilesPage } from "../components/Files/FilesPage";
import { ConsultPage } from "../components/Consult/ConsultPage";
import { CompanyPage } from "../components/Company/CompanyPage";
import { ProjectPage } from "../components/Project/ProjectPage";
import { IncidencePage } from "../components/Incidence/IncidencePage";
import { UserPage } from "../components/User/UserPage";
import { UserProjectPage } from "../components/UserProject/UserProjectPage";
import { RolesPage } from "../components/User/Roles/RolesPage";
import { TypesIncidencePage } from "../components/Incidence/Types/TypesIncidencePage";
import { StatusIncidencePage } from "../components/Incidence/Status/StatusIncidencePage";
import { TypesProjectPage } from "../components/Project/Types/TypesProjectPage";



export const AppRoute = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    let routes = [
        {
            path: '/',
            element: <PublicRoute user={user}><AuthPage /></PublicRoute>,
        },
        {
            path: '*',
            element: <NoMatch />,
        },
    ];

    if (user) {
        if (user.rolName === 'Admin' || user.rolName === 'Supervisor') {
            // Usuarios tipo Admin y Supervisor
            routes = [
                ...routes,
                /*  {
                     path: '/home',
                     element: <ProtectedRoute user={user}><HomePage /></ProtectedRoute>,
                 }, */
                {
                    path: '/company',
                    element: <ProtectedRoute user={user}><CompanyPage /></ProtectedRoute>,
                },
                {
                    path: '/project',
                    element: <ProtectedRoute user={user}><ProjectPage /></ProtectedRoute>,
                },

                {
                    path: '/register',
                    element: <ProtectedRoute user={user}><RegisterPage /></ProtectedRoute>,
                },
                {
                    path: '/report',
                    element: <ProtectedRoute user={user}><ReportPage /></ProtectedRoute>,
                },
                {
                    path: '/files',
                    element: <ProtectedRoute user={user}><FilesPage /></ProtectedRoute>,
                },
                {
                    path: '/consult',
                    element: <ProtectedRoute user={user}><ConsultPage /></ProtectedRoute>,
                },
                {
                    path: '/incidence',
                    element: <ProtectedRoute user={user}><IncidencePage /></ProtectedRoute>,
                },
                {
                    path: '/incidence-type',
                    element: <ProtectedRoute user={user}><TypesIncidencePage /></ProtectedRoute>,
                },
                {
                    path: '/incidence-status',
                    element: <ProtectedRoute user={user}><StatusIncidencePage /></ProtectedRoute>,
                },
                {
                    path: '/user',
                    element: <ProtectedRoute user={user}><UserPage /></ProtectedRoute>,
                },

                {
                    path: '/user-project',
                    element: <ProtectedRoute user={user}><UserProjectPage /></ProtectedRoute>,
                },

                {
                    path: '/user-roles',
                    element: <ProtectedRoute user={user}><RolesPage /></ProtectedRoute>,
                },
                {
                    path: '/example',
                    element: <ProtectedRoute user={user}><ExamplePage /></ProtectedRoute>,
                },
                {
                    path: '/project-types',
                    element: <ProtectedRoute user={user}><TypesProjectPage /></ProtectedRoute>,
                },
            ];
        } else if (user.rolName === 'Analista') {
            routes = [
                ...routes,

                {
                    path: '/incidence',
                    element: <ProtectedRoute user={user}><IncidencePage /></ProtectedRoute>,
                },
                {
                    path: '/incidence-status',
                    element: <ProtectedRoute user={user}><StatusIncidencePage /></ProtectedRoute>,
                },

                {
                    path: '/project',
                    element: <ProtectedRoute user={user}><ProjectPage /></ProtectedRoute>,
                },
                {
                    path: '/report',
                    element: <ProtectedRoute user={user}><ReportPage /></ProtectedRoute>,
                },
            ];
        }
    }

    return useRoutes(routes);
};