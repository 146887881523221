import axios from "axios";
import { BASEURL } from "./constantes";

const getRolesService = async () => {
    try {
        const response = await axios.get(BASEURL + "/roles");
        return response.data;
    } catch (e) {
        const error = e?.code;
        if (error === "ERR_BAD_REQUEST") {
            return [];
        } else {
            return e?.response?.data;
        }
    }
};


const addRolService = async (data) => {
    try {
        const response = await axios.post(BASEURL + "/roles", data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

const updateRolService = async (id, data) => {
    try {
        const response = await axios.patch(BASEURL + "/roles/" + id, data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};



const deleteRolService = async (id) => {
    try {
        const response = await axios.delete(BASEURL + "/roles/" + id);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }

};

export {
    getRolesService,
    addRolService,
    updateRolService,
    deleteRolService
};
