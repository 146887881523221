import React from 'react'
import { Navigate } from 'react-router-dom';

// Si no hay usuario logueado entonces enviarme al login
export const ProtectedRoute = ({ user, children }) => {
    if (!user) {
        return (
            <Navigate to="/" replace />
        );
    }

    return children;
}
