import axios from "axios";
import { BASEURL } from "./constantes";

const getUserProjectsService = async () => {
    const response = await axios.get(BASEURL + "/users/project");
    if (response.status === 200) {
        return response.data;
    } else {
        return [];
    }

};


const addUserProjectService = async (data) => {
    try {
        const response = await axios.post(BASEURL + "/users/project", data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

const updateUserProjectService = async (id, data) => {
    try {
        const response = await axios.patch(BASEURL + "/users/project/" + id, data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};



const deleteUserProjectService = async (id) => {
    try {
        const response = await axios.delete(BASEURL + "/users/project/" + id);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }

};

export { getUserProjectsService, deleteUserProjectService, updateUserProjectService, addUserProjectService, };
