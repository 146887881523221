import axios from "axios";
import { BASEURL } from "./constantes";



const loginService = async (data) => {
    try {
        const response = await axios.post(BASEURL + "/users/auth", data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

export { loginService };
