import { Button } from "@material-ui/core";
import { DeleteOutline, SaveOutlined } from "@material-ui/icons";
import { FileOpenRounded } from "@mui/icons-material";
import ModalClose from "@mui/joy/ModalClose";
import {
  Autocomplete,
  Box,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  addIncidenceService,
  updateIncidenceService,
} from "../../services/incidence_service";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const IncidenceModal = ({
  open,
  handleClose,
  data,
  getData,
  projects,
  typeIncidences,
  statusIncidences,
  users,
  modalView = false,
}) => {
  const profile = JSON.parse(localStorage.getItem("user"));

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [responsible, setResponsible] = useState("");
  const [project, setProject] = useState("");
  const [typeIncidence, setTypeIncidence] = useState("");
  const [statusIncidence, setStatusIncidence] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [enableImputfile, setEnableImputfile] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [sendService, setSendService] = useState(false);

  const handleFileChange = (e) => {
    const files = e.target.files;
    const filesArray = [];
    if (files.length + selectedFiles.length <= 4) {
      for (const file of files) {
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        }).then((res) => {
          const payloadimg = {
            fileName: file.name,
            fileValue: res,
            urlFile: URL.createObjectURL(file),
            insert: true,
          };
          filesArray.push(payloadimg);
          setSelectedFiles([...selectedFiles, ...filesArray]);
          if (selectedFiles.length + filesArray.length > 3) {
            setEnableImputfile(true);
          }
        });
      }
    }
  };

  const removeFile = (index) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
    if (selectedFiles.length <= 4) {
      setEnableImputfile(false);
    }
  };

  const onHandleClick = async () => {
    var response;
    var payload = {
      title,
      description,
      userId: profile?.id,
      selectedFiles: selectedFiles.length > 0 ? selectedFiles : [],
    };

    if (responsible?.id) {
      payload.responsibleId = responsible?.id;
    }

    if (project?.id) {
      payload.projectId = project?.id;
    }

    if (typeIncidence?.id) {
      payload.incidentTypeId = typeIncidence?.id;
    }

    if (statusIncidence?.id) {
      payload.incidentStatusId = statusIncidence?.id;
    }
    setSendService(true);
    if (data !== null) {
      response = await updateIncidenceService(data?.id, payload);
    } else {
      response = await addIncidenceService(payload);
    }
    handleClose();
    if (response?.statusCode === 201 || response?.statusCode === 200) {
      await Swal.fire(
        "OK",
        data === null ? "Creado correctamente" : "Actualizado correctamente",
        "success"
      );
      getData();
    } else {
      Swal.fire("Error", response?.message, "error");
    }
  };

  const onChangeProject = (data) => {
    if (data) {
      setProject(data);
    } else {
      setProject("");
    }
  };

  const onChangeTypeIncidence = (data) => {
    if (data) {
      setTypeIncidence(data);
    } else {
      setTypeIncidence("");
    }
  };

  const onChangeStatusIncidence = (data) => {
    if (data) {
      setStatusIncidence(data);
    } else {
      setStatusIncidence("");
    }
  };

  const onChangeUser = (data) => {
    if (data) {
      console.log("data user", data);
      setResponsible(data);
    } else {
      setResponsible("");
    }
  };

  useEffect(() => {
    if (data !== null) {
      setTitle(data?.title);
      setDescription(data?.description);
      setProject(
        data?.project?.name
          ? {
              id: data?.project?.id,
              label: data?.project?.name,
              value: data?.project?.id,
            }
          : null
      );
      setResponsible(
        data?.responsible?.nombre
          ? {
              id: data?.responsible?.id,
              label: data?.responsible?.nombre,
              value: data?.responsible?.id,
            }
          : null
      );
      setTypeIncidence(
        data?.incidentType?.campo
          ? {
              id: data?.incidentType?.id,
              label: data?.incidentType?.campo,
              value: data?.incidentType?.id,
            }
          : null
      );
      setStatusIncidence(
        data?.incidentStatus?.campo
          ? {
              id: data?.incidentStatus?.id,
              label: data?.incidentStatus?.campo,
              value: data?.incidentStatus?.id,
            }
          : null
      );
      if (data?.filestext) {
        console.log("data?.filestext", data?.filestext);
        const filsSplit = data.filestext.split("|||");
        if (Array.isArray(filsSplit)) {
          const arrayFiles = [];
          for (const file of filsSplit) {
            console.log("file for", file);
            const fileobject = file.split("||");
            console.log("fileobject for ", fileobject);
            const payloadimg = {
              fileName: fileobject[1],
              fileValue: null,
              urlFile: fileobject[0],
              insert: false,
            };
            arrayFiles.push(payloadimg);
          }
          setSelectedFiles([...selectedFiles, ...arrayFiles]);
          if (arrayFiles.length > 3) {
            setEnableImputfile(true);
          }
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (
      title !== "" &&
      description !== "" &&
      responsible !== "" &&
      project !== "" &&
      typeIncidence !== "" &&
      statusIncidence !== ""
    ) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, [
    title,
    description,
    responsible,
    project,
    typeIncidence,
    statusIncidence,
  ]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={
            window.matchMedia("(max-width: 800px)").matches
              ? { maxHeight: "700px", overflowY: "scroll" }
              : { maxHeight: "900px", overflowY: "scroll" }
          }
        >
          <div style={{ display: "flex" }}>
            <ModalClose variant="plain" sx={{ m: 1 }} onClick={handleClose} />
            <Typography
              variant="h5"
              style={{ marginBottom: "20px", fontWeight: "revert" }}
            >
              {modalView ? "" : data === null ? "Agregar" : "Editar"} Incidencia
            </Typography>
          </div>

          <Grid container spacing={4} style={{}}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Autocomplete
                disabled={
                  modalView || sendService || profile?.rolName === "Analista"
                }
                value={project}
                fullWidth
                id="combo-box-project"
                options={projects}
                onChange={(_, value) => {
                  onChangeProject(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="projectId"
                    label="Proyecto"
                    disabled={modalView || sendService}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Autocomplete
                disabled={
                  modalView || sendService || profile?.rolName === "Analista"
                }
                value={typeIncidence}
                fullWidth
                id="combo-box-type-incidence"
                options={typeIncidences}
                onChange={(_, value) => {
                  onChangeTypeIncidence(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="typeIncidence"
                    label="Tipo"
                    disabled={modalView || sendService}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Autocomplete
                disabled={modalView || sendService}
                value={statusIncidence}
                fullWidth
                id="combo-box-status-incidence"
                options={statusIncidences}
                onChange={(_, value) => {
                  onChangeStatusIncidence(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="statusIncidence"
                    label="Estado"
                    disabled={modalView || sendService}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Autocomplete
                disabled={
                  modalView || sendService || profile?.rolName === "Analista"
                }
                value={responsible}
                fullWidth
                id="combo-box-project"
                options={users}
                onChange={(_, value) => {
                  onChangeUser(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="userId"
                    label="Responsable"
                    disabled={modalView || sendService}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                disabled={
                  modalView || sendService || profile?.rolName === "Analista"
                }
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
                id="title"
                label="Titulo"
                type="text"
                autoComplete="off"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                disabled={
                  modalView || sendService || profile?.rolName === "Analista"
                }
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                id="description"
                label="Descripción"
                type="text"
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {!modalView && !sendService ? (
                !enableImputfile ? (
                  <div>
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg, .png, .docx"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      id="fileInput"
                      multiple
                      disabled={modalView || sendService}
                    />
                    <label htmlFor="fileInput" style={{ cursor: "pointer" }}>
                      <div
                        style={{
                          width: "150px",
                          backgroundColor: "grey",
                          color: "white",
                          padding: "10px",
                          borderRadius: "5px",
                          textAlign: "center",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FileOpenRounded />
                        &nbsp;Subir Archivo
                      </div>
                    </label>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}

              <div>
                {selectedFiles.length > 0 && (
                  <div style={{ marginTop: "10px" }}>
                    <Typography variant="h6" align="left">
                      Archivos Seleccionados: {selectedFiles.length}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                      }}
                    >
                      {selectedFiles.map((file, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              width: "330px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <a href={file.urlFile}>{file.fileName}</a>
                            {/* {file.type.includes("image") ? (
                            <div>
                              <img
                                src={URL.createObjectURL(file)}
                                alt="Selected"
                                style={{
                                  width: "150px",
                                  height: "150px",
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                          ) : (
                            <p>{file.name}</p>
                          )} */}
                            {!modalView && !sendService ? (
                              <div>
                                <Button
                                  onClick={() => removeFile(index)}
                                  variant="contained"
                                  color="error"
                                >
                                  <DeleteOutline />
                                </Button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {!modalView ? (
                <Button
                  disabled={!enabled || sendService}
                  onClick={() => onHandleClick()}
                  variant="contained"
                  fullWidth
                  style={{ height: "50px" }}
                  startIcon={<SaveOutlined />}
                >
                  {data === null
                    ? sendService
                      ? "Agregando"
                      : "Agregar"
                    : sendService
                    ? "Actualizando"
                    : "Actualizar"}
                </Button>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
