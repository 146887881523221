import { Logout } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Button, Stack } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { deepOrange } from '@mui/material/colors';
import { styled, useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spacer } from '../../utils/Spacer';

// Icons
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import OutlinedFlagOutlinedIcon from '@mui/icons-material/OutlinedFlagOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';

const drawerWidth = 250;


const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,

}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


export const CustomDrawer = ({ children }) => {

    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'))

    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [mobile, setMobile] = useState(false);

    const handleDrawerOpen = () => {
        const mediaQuery = window.matchMedia('(max-width: 768px)').matches;
        if (mediaQuery) {
            setMobile(!mobile)
        }
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleLogout = () => {
        localStorage.removeItem('user');
        navigate('/')

    }

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setOpen(!mediaQuery.matches);
    setMobile(mediaQuery.matches)

    const handler = (e) => {
        setOpen(!e.matches);
        setMobile(e.matches);
    };
    mediaQuery.addListener(handler);

    return () => mediaQuery.removeListener(handler);
  }, []);

    const routes = [
        /* {
            'id': 1,
            'name': 'Inicio',
            'path': '/home',
            'enabled': true,
            'icon': <HomeOutlinedIcon />
        }, */
        /*    {
               'id': 2,
               'name': 'Registrar',
               'path': '/register',
               'enabled': true,
           },
           {
               'id': 3,
               'name': 'Archivos',
               'path': '/files',
               'enabled': user?.rolId != 1,
           },
           {
               'id': 4,
               'name': 'Consultar',
               'path': '/consult',
               'enabled': user?.rolId != 1,
           }, */
        {
            'id': 5,
            'name': 'Reportes',
            'path': '/report',
            'enabled': true,
            'icon': <OutlinedFlagOutlinedIcon />
        },
        /* {
            'id': 6,
            'name': 'Empresas',
            'path': '/company',
            'enabled': user?.rolId != 1,
            'icon': <BusinessOutlinedIcon />
        }, */
        /*  {
             'id': 7,
             'name': 'Proyectos',
             'path': '/project',
             'enabled': user?.rolId != 1,
             'icon': <BusinessCenterOutlinedIcon />
         }, */
        {
            'id': 8,
            'name': 'Incidencias',
            'path': '/incidence',
            'enabled': true,
            'icon': <QuizOutlinedIcon />
        },
        {
            'id': 9,
            'name': 'Usuarios',
            'path': '/user',
            'enabled': user?.rolName === 'Admin',
            'icon': <GroupOutlinedIcon />
        },
        /* {
            'id': 9,
            'name': 'Proyecto de Usuarios',
            'path': '/user-project',
            'enabled': user?.rolId != 1,
            'icon': <Diversity1OutlinedIcon />
        }, */
    ];



    return (

        <Box sx={{ display: '-webkit-box' }}>
            <AppBar position='fixed'>
                <Toolbar >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        
                    >
                        <MenuIcon />
                    </IconButton>
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                    }}>
                        <Typography variant="h6" noWrap component="div">
                            <Stack>
                                <div style={{ fontSize: '1.0em', fontWeight: 'bold' }}>{user?.nombre?.toUpperCase()}</div>
                                <div style={{ fontSize: '0.7em', color: '#cbcbcb' }}>Rol: {user?.rolName}</div>
                            </Stack>

                        </Typography>
                    </div>
                    <Spacer />
                    <Button variant='contained' onClick={handleLogout} color='error'>
                        <Logout />
                    </Button>

                </Toolbar>
            </AppBar>
            {!mobile?(
               <Drawer variant="permanent" open={open} style={window.matchMedia('(max-width: 768px)').matches?{width:"0%"}:{}}>
                <DrawerHeader>
                    <Spacer flex={2} />
                    <Avatar sx={{ bgcolor: deepOrange[500] }}>{user?.nombre?.charAt(0)?.toUpperCase()}</Avatar>
                    <Spacer />
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List key={0}>
                    {routes.map((text, index) => {
                        if (text?.enabled) {
                            return <ListItem key={text?.id} disablePadding sx={{ display: 'block' }} onClick={() => {
                                navigate(text?.path);
                            }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {text?.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={text?.name} sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                        } else {
                            return <></>
                        }
                    })}
                </List>
            </Drawer> 
            ):""}
            

            <Box component="main" sx={{ flexGrow: 1, p: 3 }} style={{ backgroundColor: '#f7f7f7' }}>
                <DrawerHeader />
                {children}
            </Box>
        </Box>
    )
}
