
import { CustomDrawer } from '../Drawer/CustomDrawer';
import { Box, Button, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import { Search } from '@material-ui/icons';
import { useState } from 'react';




const columns = [
    { id: 'id', label: 'ID', minWidth: 70 },
    { id: 'responsable', label: 'Responsable', minWidth: 100 },
    { id: 'fecha', label: 'Fecha', minWidth: 100 },
    { id: 'empresa', label: 'Empresa', minWidth: 100 },
    { id: 'analista', label: 'Analista', minWidth: 100 },
    { id: 'archivos', label: 'Archivos', minWidth: 100 },
];

function createData(id, responsable, fecha, empresa, analista, archivos) {
    const archivosCount = archivos.length;
    const archivosButton = (
        <Button onClick={() => verArchivos(id)} variant="contained"
        >
            VER {archivosCount} archivo{archivosCount === 1 ? '' : 's'}
        </Button>
    );

    return { id, responsable, fecha, empresa, analista, archivosButton };
}

// Agrega una función para mostrar los archivos cuando se hace clic en el botón
function verArchivos(id) {
    const row = rows.find(row => row.id === id);
    if (row) {
        // Aquí puedes implementar la lógica para mostrar los archivos según tu aplicación
        // Por ejemplo, puedes abrir un modal o una ventana emergente con los archivos.
    }
}





const rows = [
    createData('1', 'Snow', '25/08/2023', 'Primax', 'Luis Ramos Aliaga', ['archivo.png', 'archivo2.png']),
    createData('2', 'Snow', '25/08/2023', 'Primax', 'Luis Ramos Aliaga', ['archivo.png', 'archivo2.png']),
    createData('3', 'Snow', '25/08/2023', 'Primax', 'Luis Ramos Aliaga', ['archivo.png', 'archivo2.png']),
    createData('4', 'Snow', '25/08/2023', 'Primax', 'Luis Ramos Aliaga', ['archivo.png', 'archivo2.png']),
    createData('5', 'Snow', '25/08/2023', 'Primax', 'Luis Ramos Aliaga', ['archivo.png', 'archivo2.png']),
    createData('6', 'Snow', '25/08/2023', 'Primax', 'Luis Ramos Aliaga', ['archivo.png', 'archivo2.png']),
    createData('7', 'Snow', '25/08/2023', 'Primax', 'Luis Ramos Aliaga', ['archivo.png', 'archivo2.png']),
    createData('8', 'Snow', '25/08/2023', 'Primax', 'Luis Ramos Aliaga', ['archivo.png', 'archivo2.png']),
    createData('9', 'Snow', '25/08/2023', 'Primax', 'Luis Ramos Aliaga', ['archivo.png', 'archivo2.png']),
    createData('10', 'Snow', '25/08/2023', 'Primax', 'Luis Ramos Aliaga', ['archivo.png', 'archivo2.png']),
    createData('11', 'Snow', '25/08/2023', 'Primax', 'Luis Ramos Aliaga', ['archivo.png', 'archivo2.png']),
    createData('12', 'Snow', '25/08/2023', 'Primax', 'Luis Ramos Aliaga', ['archivo.png', 'archivo2.png']),
    createData('13', 'Snow', '25/08/2023', 'Primax', 'Luis Ramos Aliaga', ['archivo.png', 'archivo2.png']),
    createData('14', 'Snow', '25/08/2023', 'Primax', 'Luis Ramos Aliaga', ['archivo.png', 'archivo2.png']),
    createData('15', 'Snow', '25/08/2023', 'Primax', 'Luis Ramos Aliaga', ['archivo.png', 'archivo2.png']),
    createData('16', 'Snow', '25/08/2023', 'Primax', 'Luis Ramos Aliaga', ['archivo.png', 'archivo2.png']),
    createData('17', 'Snow', '25/08/2023', 'Primax', 'Luis Ramos Aliaga', ['archivo.png', 'archivo2.png']),
];

export const FilesPage = () => {

    const [search, setSearch] = useState('');
    const [data, setData] = useState(rows);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };



    const onChangeSearch = (e) => {
        const text = e?.target?.value;
        setSearch(text);


        const filteredData = rows.filter((row) => {
            return row.id.toString().includes(text) || row.responsable.toLowerCase().includes(text.toLowerCase());
        });

        setData(filteredData);

    }

    return (
        <div style={{ height: '100vh', backgroundColor: '#f7f7f7' }}>
            <CustomDrawer>
                <Typography variant="h5" style={{ marginBottom: '30px', fontWeight: 'revert' }}>
                    Archivos
                </Typography>

                <Box style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '5px', border: '1px solid #ccc' }}>
                    <TextField
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton style={{ padding: '0px' }}>
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        value={search}
                        onChange={onChangeSearch}
                        fullWidth
                        id="search"
                        label="Buscar por ID o Responsable"
                        type='text'
                        autoComplete='off'
                    />

                    <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '30px' }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            return (
                                                <TableRow hover tabIndex={-1} key={row.id}>
                                                    {columns.map((column) => {
                                                        if (column.id === 'archivos') {
                                                            // Renderiza el botón en la columna "Archivos"
                                                            return (
                                                                <TableCell key={column.id} align={column.align}>
                                                                    {row.archivosButton}
                                                                </TableCell>
                                                            );
                                                        } else {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell key={column.id} align={column.align}>
                                                                    {value}
                                                                </TableCell>
                                                            );
                                                        }
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>




            </CustomDrawer>
        </div >


    );
}