
import { CustomDrawer } from '../Drawer/CustomDrawer';
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { Search } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { Spacer } from '../../utils/Spacer';
import { getCompanysService } from '../../services/company_service';
import Swal from 'sweetalert2';
import { ProjectModal } from './ProjectModal';
import { deleteProjectService, getProjectsService } from '../../services/project_service';
import { getTypeProjectsService } from '../../services/type_project_service';
import { StripedDataGrid } from '../../utils/StripedDataGrid';
import { ArrowBack, SaveOutlined } from '@mui/icons-material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Link, useNavigate } from 'react-router-dom';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';


export const ProjectPage = () => {

    const profile = JSON.parse(localStorage.getItem('user'))


    const [search, setSearch] = useState('');

    const [projects, setProjects] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [typeProjects, setTypeProjects] = useState([]);

    const [filteredProjects, setFilteredProjects] = useState([]);

    const [dataEdited, setDataEdited] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();



    const onChangeSearch = (e) => {
        const term = e.target.value;
        setSearch(term);
        if (term.trim() === '') {
            setFilteredProjects(projects);
        } else {
            const filtered = projects.filter(project =>
                project.name.toLowerCase().includes(term.toLowerCase()) ||
                project.company?.businessName.toLowerCase().includes(term.toLowerCase())
            );
            setFilteredProjects(filtered);
        }
    }


    useEffect(() => {
        fetchGetProjects();
        fetchGetCompany();
        fetchTypeProjects();
    }, [])

    const fetchGetProjects = async () => {
        const response = await getProjectsService();
        setProjects(response);
        setFilteredProjects(response);
    }

    const fetchGetCompany = async () => {
        const response = await getCompanysService();
        const responseParsed = response.map(e => {
            return {
                'id': e.id,
                'label': e.businessName,
                'value': e.id,
            }
        })
        setCompanies(responseParsed);
    }

    const fetchTypeProjects = async () => {
        const response = await getTypeProjectsService();
        const responseParsed = response.map(e => {
            return {
                'id': e.id,
                'label': e.valor,
                'value': e.id,
            }
        })
        setTypeProjects(responseParsed);

    }


    const onHandleAddProject = () => {
        setDataEdited(null);
        setShowModal(true);
    }

    const onHandleEditProject = (data) => {
        setDataEdited(data);
        setShowModal(true);
    }

    const onHandleDeleteProject = async (id) => {
        const response = await deleteProjectService(id);
        if (response?.statusCode === 200) {
            Swal.fire("OK", 'Eliminado correctamente', "success");
        } else {
            Swal.fire("Error", "Ocurrió un error", "error");
        }
        fetchGetProjects();
    }




    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Nombre', width: 200 },
        {
            field: 'company',
            headerName: 'Empresa',
            width: 200,
            renderCell: (params) => {
                return <>
                    <div>{params?.row?.company?.businessName ?? 'No hay'}</div>
                </>
            }
        },
        {
            field: 'typeProject',
            headerName: 'Tipo de Proyecto',
            width: 200,
            renderCell: (params) => {
                return <>
                    <div>{params?.row?.typeProject?.valor ?? 'No hay'}</div>
                </>
            }
        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            width: 260,
            renderCell: (params) => (
                <>
                    <Button startIcon={<EditOutlinedIcon />} onClick={() => onHandleEditProject(params?.row)} variant='contained' color='success' disabled={profile?.rolName === "Analista"} >
                        Editar
                    </Button>
                    <Spacer />
                    <Button startIcon={<DeleteOutlineOutlinedIcon />} onClick={() => { onHandleDeleteProject(params?.row?.id) }} variant='contained' color='error' disabled={profile?.rolName === "Analista"}>
                        Eliminar
                    </Button>
                </>
            ),
        },
    ];

    const handleGoBack = () => {
        navigate(-1)
    };

    return (
        <div style={{ height: '100vh', backgroundColor: '#f7f7f7' }}>
            <CustomDrawer>
                <Button startIcon={<ArrowBack />} color='error' variant='outlined' onClick={handleGoBack}>
                    Volver
                </Button>

                <Typography variant="h5" style={{ marginBottom: '30px', fontWeight: 'revert', marginTop: '20px' }}>
                    Proyectos
                </Typography>

                {
                    profile?.rolName !== "Analista" && <div>
                        <Link to='/company'>
                            <Button startIcon={<SearchOutlinedIcon />} variant='outlined'>Ver Empresas</Button>
                        </Link>
                        &nbsp;&nbsp;
                        <Link to='/project-types'>
                            <Button startIcon={<SearchOutlinedIcon />} variant='outlined'>Ver Tipos de Proyectos</Button>
                        </Link>
                    </div>
                }



                <Box style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '5px', border: '1px solid #ccc', marginTop: '30px' }}>

                    <div style={{ display: 'flex' }}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton style={{ padding: '0px' }}>
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            value={search}
                            onChange={onChangeSearch}
                            fullWidth
                            id="search"
                            label="Buscar"
                            type='text'
                            autoComplete='off'


                        />
                        {
                            profile?.rolName !== "Analista" &&
                            <Button startIcon={<SaveOutlined />} variant='contained' style={{ marginLeft: '10px' }} onClick={() => onHandleAddProject()}>
                                Agregar
                            </Button>
                        }

                    </div>


                    <div style={{ height: 400, width: '100%', marginTop: '20px' }}>
                        <StripedDataGrid
                            hideFooterSelectedRowCount
                            rowSelection={false}
                            rows={filteredProjects}
                            columns={columns}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : ''
                            }
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}

                            pageSizeOptions={[5, 10]}
                        />
                    </div>
                </Box>
            </CustomDrawer>

            {showModal &&
                <ProjectModal
                    open={showModal}
                    handleClose={() => setShowModal(false)}
                    data={dataEdited}
                    companies={companies}
                    typeProjects={typeProjects}
                    getData={() => {
                        setShowModal(false)
                        fetchGetProjects();
                    }}
                />}
        </div>
    );
}