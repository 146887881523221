import { Grid, IconButton, InputAdornment } from '@material-ui/core';
import { Email } from '@material-ui/icons';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Autocomplete, Box, Button, Stack, TextField, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { loginService } from '../../services/auth_service';
import { getRolesService } from '../../services/roles_service';

export const AuthPage = () => {

  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rolType, setRolType] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [listRolType, setListRolType] = useState([]);


  useEffect(() => {
    fetchGetRoles();
  }, []);


  const fetchGetRoles = async () => {
    const response = await getRolesService();
    const responseParsed = response?.map(e => {
      return {
        'key': e?.id,
        'label': e?.campo,
        'value': e?.id,
      }
    });
    setListRolType(responseParsed);

  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onChangeUsername = (e) => setUsername(e.target.value);

  const onChangePassword = (e) => setPassword(e.target.value);

  const onChangeRolType = (data) => {
    if (data) {
      setRolType(data);
    } else {
      setRolType('');
    }
  }

  const handleLogin = async () => {
    const data = {
      email: username,
      password: password,
      rolId: rolType
    }

    const response = await loginService(data);
    if (response?.statusCode === 404) {
      Swal.fire("Error", response?.message, "error");
    } else {
      //reload();
      //Swal.fire("Eliminado con éxito");
      localStorage.setItem('user', JSON.stringify(response))
      navigate('/incidence')
    }
  }

  useEffect(() => {
    if (username !== '' &&
      password !== '' &&
      rolType !== '') {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [username,
    password,
    rolType,])



  return (
    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', backgroundImage:"url(https://d35184sb7415x4.cloudfront.net/3409297.jpg)", height: '100vh', width: '100vw', alignItems: 'center', }}>
      <Grid item xs={12} xl={4} md={4} sm={6}>
        <h1 style={{ textAlign: 'center', fontWeight: 'bold', fontSize:"46px", color:"aliceblue" }}>STI</h1>
        <Stack spacing={3} style={{ backgroundColor: 'white', margin: '20px', padding: '20px', borderRadius: '2px' }}>
          <h2 style={{ textAlign: 'center', margin: '10px', fontWeight: 'normal' }}>Iniciar Sesión</h2>
          <Autocomplete
            disablePortal
            id="combo-box-type-user"
            options={listRolType}
            onChange={(_, data) => {
              onChangeRolType(data?.value);
            }}
            renderInput={(params) => <TextField {...params} id='rolType' label="Tipo de Usuario" value={rolType} />}
          />



          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton style={{ padding: '0px' }}>
                    <Email />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
            id="username"
            label="Email"
            type='email'
            autoComplete='off'
            value={username}
            onChange={onChangeUsername}
          />
          <TextField
            fullWidth
            autoComplete='off'
            id="password"
            label="Contraseña"
            type={showPassword ? 'text' : 'password'}
            value={password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={onChangePassword}
          />

          <Button
            style={{ height: '50px' }}
            disabled={disabled}
            onClick={handleLogin}
            variant='contained'
            fullWidth>
            Ingresar
          </Button>

        </Stack>
      </Grid>
    </Box>

  )
}
