
import { Search } from '@material-ui/icons';
import { SaveOutlined } from '@mui/icons-material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getRolesService } from '../../services/roles_service';
import { deleteUserService, getUserService } from '../../services/user_service';
import { Spacer } from '../../utils/Spacer';
import { StripedDataGrid } from '../../utils/StripedDataGrid';
import { CustomDrawer } from '../Drawer/CustomDrawer';
import { UserModal } from './UserModal';

export const UserPage = () => {

    const [search, setSearch] = useState('');
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);

    const [dataEdited, setDataEdited] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const onChangeSearch = (e) => {
        const term = e.target.value;
        setSearch(term);
        if (term.trim() === '') {
            setFilteredUsers(users);
        } else {
            const filtered = users.filter(user =>
                user.nombre.toLowerCase().includes(term.toLowerCase()) ||
                user.email.toLowerCase().includes(term.toLowerCase()) ||
                user.document.toLowerCase().includes(term.toLowerCase()) ||
                user.rol?.valor?.toLowerCase().includes(term.toLowerCase())
            );
            setFilteredUsers(filtered);
        }
    }


    useEffect(() => {
        fetchGetUsers();
        fetchGetRoles();
    }, [])

    const fetchGetUsers = async () => {
        const response = await getUserService();
        setUsers(response);
        setFilteredUsers(response);
    }

    const fetchGetRoles = async () => {
        const response = await getRolesService();
        const responseParsed = response.map(e => {
            return {
                'id': e?.id,
                'label': e?.campo,
                'value': e?.id,
            }
        });
        setRoles(responseParsed);
    }

    const onHandleAddUser = () => {
        setDataEdited(null);
        setShowModal(true);
    }

    const onHandleEditUser = (data) => {
        setDataEdited(data);
        setShowModal(true);
    }

    const onHandleDeleteUser = async (id) => {
        const response = await deleteUserService(id);
        if (response?.statusCode === 200) {
            Swal.fire("OK", 'Eliminado correctamente', "success");
        } else {
            Swal.fire("Error", "Ocurrió un error", "error");
        }
        fetchGetUsers();
    }



    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'nombre', headerName: 'Nombre', width: 200 },
        { field: 'apellidos', headerName: 'apellidos', width: 250 },
        { field: 'email', headerName: 'Email', width: 200 },
        //{ field: 'password', headerName: 'Contraseña', width: 200 },
        { field: 'document', headerName: 'Documento', width: 200 },
        {
            field: 'rol',
            headerName: 'Tipo de Rol',
            width: 200,
            renderCell: (params) => {
                return <>
                    <div>{params?.row?.rol?.valor}</div>
                </>
            }
        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            width: 260,
            renderCell: (params) => (
                <>
                    <Button startIcon={<EditOutlinedIcon />} onClick={() => onHandleEditUser(params?.row)} variant='contained' color='success'>
                        Editar
                    </Button>
                    <Spacer />
                    <Button startIcon={<DeleteOutlineOutlinedIcon />} onClick={() => { onHandleDeleteUser(params?.row?.id) }} variant='contained' color='error'>
                        Eliminar
                    </Button>
                </>
            ),
        },
    ];


    return (
        <div style={{ height: '100vh', backgroundColor: '#f7f7f7' }}>
            <CustomDrawer>
                <Typography variant="h5" style={{ marginBottom: '30px', fontWeight: 'revert' }}>
                    Usuarios
                </Typography>


                <Link to='/user-roles'>
                    <Button startIcon={<SearchOutlinedIcon />} variant='outlined'>Ver Roles</Button>
                </Link>

                <Box style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '5px', border: '1px solid #ccc', marginTop: '20px' }}>

                    <div style={{ display: 'flex' }}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton style={{ padding: '0px' }}>
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            value={search}
                            onChange={onChangeSearch}
                            fullWidth
                            id="search"
                            label="Buscar"
                            type='text'
                            autoComplete='off'


                        />
                        <Button startIcon={<SaveOutlined />} variant='contained' style={{ marginLeft: '10px' }} onClick={() => onHandleAddUser()}>
                            Agregar
                        </Button>
                    </div>


                    <div style={{ height: 400, width: '100%', marginTop: '20px' }}>
                        <StripedDataGrid
                            hideFooterSelectedRowCount
                            rowSelection={false}
                            disableRowSelectionOnClick
                            rows={filteredUsers}
                            autoHeight
                            columns={columns}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : ''
                            }
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}

                            pageSizeOptions={[5, 10]}
                        />
                    </div>
                </Box>
            </CustomDrawer>

            {showModal &&
                <UserModal
                    open={showModal}
                    handleClose={() => setShowModal(false)}
                    data={dataEdited}
                    getData={() => {
                        setShowModal(false)
                        fetchGetUsers();
                    }}
                    roles={roles}
                />}
        </div>
    );
}