
import { Search } from '@material-ui/icons';
import { AppRegistrationRounded, SaveOutlined } from '@mui/icons-material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { deleteIncidenceService, getIncidencesAnalistaService, getIncidencesService, getIncidencesSupervisorService, getStatusIncidencesService, getTypesIncidencesService } from '../../services/incidence_service';
import { getProjectsService } from '../../services/project_service';
import { getUserService } from '../../services/user_service';
import { Spacer } from '../../utils/Spacer';
import { StripedDataGrid } from '../../utils/StripedDataGrid';
import { CustomDrawer } from '../Drawer/CustomDrawer';
import { IncidenceModal } from './IncidenceModal';

export const IncidencePage = () => {

    const profile = JSON.parse(localStorage.getItem('user'))
    const [search, setSearch] = useState('');

    const [incidences, setIncidences] = useState([]);
    const [projects, setProjects] = useState([]);
    const [typeIncidences, setTypeIncidences] = useState([]);
    const [filteredIncidences, setFilteredIncidences] = useState([]);
    const [statusIncidences, setStatusIncidences] = useState([]);
    const [users, setUsers] = useState([]);

    const [dataEdited, setDataEdited] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalView, setModalView] = useState(false)

    const onChangeSearch = (e) => {
        const term = e.target.value;
        setSearch(term);
        if (term.trim() === '') {
            setFilteredIncidences(incidences);
        } else {
            const filtered = incidences.filter(incidence =>
                incidence.title.toLowerCase().includes(term.toLowerCase()) ||
                incidence.description.toLowerCase().includes(term.toLowerCase()) ||
                incidence.project?.name.toLowerCase().includes(term.toLowerCase())
            );
            setFilteredIncidences(filtered);
        }
    }


    useEffect(() => {
        fetchGetIncidences();
        fetchGetProjects();
        fetchGetTypeIncidences();
        fetchGetStatusIncidences();
        fetchGetUsers();
    }, [])

    const fetchGetIncidences = async () => {
        var response;
        if (profile?.rolName === "Supervisor") {
            response = await getIncidencesSupervisorService(profile?.id);
        } else if (profile?.rolName === "Analista") {
            response = await getIncidencesAnalistaService(profile?.id);
        } else {
            response = await getIncidencesService();
        }
        setIncidences(response);
        setFilteredIncidences(response);
    }

    const fetchGetProjects = async () => {
        const response = await getProjectsService();
        const responseParsed = response.map(e => {
            return {
                'id': e?.id,
                'label': e?.name,
                'value': e?.id,
            }
        })
        setProjects(responseParsed);
    }

    const fetchGetTypeIncidences = async () => {
        const response = await getTypesIncidencesService();
        const responseParsed = response.map(e => {
            return {
                'id': e?.id,
                'label': e?.valor,
                'value': e?.id,
            }
        });

        setTypeIncidences(responseParsed);
    }

    const fetchGetStatusIncidences = async () => {
        const response = await getStatusIncidencesService();
        const responseParsed = response.map(e => {
            return {
                'id': e?.id,
                'label': e?.valor,
                'value': e?.id,
            }
        })
        setStatusIncidences(responseParsed);
    }

    const fetchGetUsers = async () => {
        const response = await getUserService();
        const responseParsed = response.map(e => {
            return {
                'id': e?.id,
                'label': e?.nombre,
                'value': e?.id,
            }
        })
        setUsers(responseParsed);
    }


    const onHandleAddIncidence = () => {
        setDataEdited(null);
        setShowModal(true);
        setModalView(false);
    }

    const onHandleEditIncidence = (data,modalView=false) => {
        setDataEdited(data);
        setShowModal(true);
        setModalView(modalView);
    }

    const onHandleDeleteIncidence = async (id) => {
        const response = await deleteIncidenceService(id);
        if (response?.statusCode === 200) {
            Swal.fire("OK", 'Eliminado correctamente', "success");
        } else {
            Swal.fire("Error", "Ocurrió un error", "error");
        }
        fetchGetIncidences();
    }


    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'title', headerName: 'Titulo', width: 200 },
        { field: 'description', headerName: 'Descripción', width: 200 },
        {
            field: 'project', headerName: 'Proyecto', width: 200, renderCell: (params) => {
                return <>
                    <div>{params?.row?.project?.name}</div>
                </>
            }
        },
        {
            field: 'responsible', headerName: profile?.rolName !== "Analista" ? 'Responsable' : 'Supervisor', width: 200, renderCell: (params) => {
                return <>
                    <div>{profile?.rolName !== "Analista" ? params?.row?.responsible?.nombre : params?.row?.user.nombre}</div>
                </>
            }
        },
        {
            field: 'incidentType', headerName: 'Tipo', width: 200, renderCell: (params) => {
                return <>
                    <div>{params?.row?.incidentType?.valor}</div>
                </>
            }
        },
        {
            field: 'incidentStatus',
            headerName: 'Estado',
            width: 200,
            renderCell: (params) => {
                return <>
                    <div>{params?.row?.incidentStatus?.valor}</div>
                </>
            }
        },

        {
            field: 'acciones',
            headerName: 'Acciones',
            width: 300,
            renderCell: (params) => (
                <>

                    {
                        profile?.rolName !== "Analista" && <>
                            <Button startIcon={<EditOutlinedIcon />} onClick={() => onHandleEditIncidence(params?.row)} variant='contained' color='success'>
                                Editar
                            </Button>
                            <Spacer />
                            <Button startIcon={<DeleteOutlineOutlinedIcon />} onClick={() => { onHandleDeleteIncidence(params?.row?.id) }} variant='contained' color='error'>
                                Eliminar
                            </Button>
                            <Spacer />
                            <Button startIcon={<VisibilityIcon />} onClick={() => { onHandleEditIncidence(params?.row,true) }} variant='contained' color='success'>
                                Ver
                            </Button>
                        </>
                    }


                    {
                        profile?.rolName === "Analista" && <Link to="/report">
                            <Button startIcon={<AppRegistrationRounded />} variant='contained' color='info'>
                                Informar
                            </Button>
                        </Link>
                    }

                </>
            ),
        },
    ];

    return (
        <div style={{ height: '100vh', backgroundColor: '#f7f7f7' }}>
            <CustomDrawer>
                <Typography variant="h5" style={{ marginBottom: '30px', fontWeight: 'revert' }}>
                    Incidencias
                </Typography>

                <div>
                    {
                        profile?.rolName !== "Analista" && <Link to='/incidence-type'>
                            <Button startIcon={<SearchOutlinedIcon />} variant='outlined'>Ver Tipos</Button>
                        </Link>
                    }
                    &nbsp;&nbsp;
                    <Link to='/incidence-status'>
                        <Button startIcon={<SearchOutlinedIcon />} variant='outlined'>Ver Estados</Button>
                    </Link>
                    &nbsp;&nbsp;
                    <Link to='/project'>
                        <Button startIcon={<SearchOutlinedIcon />} variant='outlined'>Ver Proyectos</Button>
                    </Link>
                </div>



                <Box style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '5px', border: '1px solid #ccc', marginTop: '20px' }}>

                    <div style={{ display: 'flex' }}>
                        <TextField

                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton style={{ padding: '0px' }}>
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            value={search}
                            onChange={onChangeSearch}
                            fullWidth
                            id="search"
                            label="Buscar"
                            type='text'
                            autoComplete='off'


                        />
                        {
                            profile?.rolName !== "Analista" && <Button startIcon={<SaveOutlined />} variant='contained' style={{ marginLeft: '10px' }} onClick={() => onHandleAddIncidence()}>
                                Agregar
                            </Button>
                        }

                    </div>


                    <div style={{ height: 400, width: '100%', marginTop: '20px' }}>
                        <StripedDataGrid
                            hideFooterSelectedRowCount
                            rowSelection={false}
                            rows={filteredIncidences}
                            columns={columns}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : ''
                            }
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}

                            pageSizeOptions={[5, 10]}
                        />
                    </div>
                </Box>
            </CustomDrawer>

            {showModal &&
                <IncidenceModal
                    open={showModal}
                    handleClose={() => setShowModal(false)}
                    data={dataEdited}
                    getData={() => {
                        setShowModal(false)
                        fetchGetIncidences();
                    }}
                    projects={projects}
                    typeIncidences={typeIncidences}
                    statusIncidences={statusIncidences}
                    users={users}
                    modalView={modalView}
                />}
        </div>
    );
}