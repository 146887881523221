import axios from "axios";
import { BASEURL } from "./constantes";

const getTypesIncidencesService = async () => {
    try {
        const response = await axios.get(BASEURL + "/incidence/types");
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};


const addTypeIncidenceService = async (data) => {
    try {
        const response = await axios.post(BASEURL + "/incidence/types", data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

const updateTypeIncidenceService = async (id, data) => {
    try {
        const response = await axios.patch(BASEURL + "/incidence/types/" + id, data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};


const deleteTypeIncidenceService = async (id) => {
    try {
        const response = await axios.delete(BASEURL + "/incidence/types/" + id);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

export {
    getTypesIncidencesService,
    addTypeIncidenceService,
    updateTypeIncidenceService,
    deleteTypeIncidenceService
};
