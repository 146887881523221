
import { CustomDrawer } from '../Drawer/CustomDrawer';
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { Search } from '@material-ui/icons';
import { useState } from 'react';
import { Spacer } from '../../utils/Spacer';
import { StripedDataGrid } from '../../utils/StripedDataGrid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';


const columns = [
    { field: 'abc', headerName: '', width: 70 },
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'responsable', headerName: 'Responsable', width: 200 },
    { field: 'fecha', headerName: 'Fecha', width: 200 },
    { field: 'empresa', headerName: 'Empresa', width: 200 },
    { field: 'analista', headerName: 'Analista', width: 200 },
    {
        field: 'acciones',
        headerName: 'Acciones',
        width: 300,
        renderCell: (params) => (
            <>
                <Button onClick={() => { console.log('params: ', params) }} variant='contained' color='primary'>
                    Ver
                </Button>
                <Spacer />
                <Button startIcon={<EditOutlinedIcon />} onClick={() => { console.log('params: ', params) }} variant='contained' color='success'>
                    Editar
                </Button>
                <Spacer />
                <Button startIcon={<DeleteOutlineOutlinedIcon />} onClick={() => { console.log('params: ', params) }} variant='contained' color='error'>
                    Eliminar
                </Button>
            </>
        ),
    },
];

const rows = [
    { id: 1, responsable: 'Snow', fecha: '25/08/2023', empresa: 'Primax', analista: 'Luis Ramos Aliaga', archivos: ['archivo.png', 'archivo2.png'] },
    { id: 2, responsable: 'Lannister', fecha: '25/08/2023', empresa: 'Primax', analista: 'Luis Ramos Aliaga', archivos: ['archivo.png', 'archivo2.png'] },
    { id: 3, responsable: 'Lannister', fecha: '25/08/2023', empresa: 'Primax', analista: 'Luis Ramos Aliaga', archivos: ['archivo.png', 'archivo2.png'] },
    { id: 4, responsable: 'Stark', fecha: '25/08/2023', empresa: 'Primax', analista: 'Luis Ramos Aliaga', archivos: ['archivo.png', 'archivo2.png'] },
    { id: 5, responsable: 'Targaryen', fecha: '25/08/2023', empresa: 'Primax', analista: 'Luis Ramos Aliaga', archivos: ['archivo.png', 'archivo2.png'] },
    { id: 6, responsable: 'Melisandre', fecha: '25/08/2023', empresa: 'Primax', analista: 'Luis Ramos Aliaga', archivos: ['archivo.png', 'archivo2.png'] },
    { id: 7, responsable: 'Clifford', fecha: '25/08/2023', empresa: 'Primax', analista: 'Luis Ramos Aliaga', archivos: ['archivo.png', 'archivo2.png'] },
    { id: 8, responsable: 'Frances', fecha: '25/08/2023', empresa: 'Primax', analista: 'Luis Ramos Aliaga', archivos: ['archivo.png', 'archivo2.png'] },
    { id: 9, responsable: 'Roxie', fecha: '25/08/2023', empresa: 'Primax', analista: 'Luis Ramos Aliaga', archivos: ['archivo.png', 'archivo2.png'] },
];


export const ConsultPage = () => {

    const [search, setSearch] = useState('');
    const [data, setData] = useState(rows);

    const onChangeSearch = (e) => {
        const text = e?.target?.value;
        setSearch(text);


        const filteredData = rows.filter((row) => {
            return row.id.toString().includes(text) || row.responsable.toLowerCase().includes(text.toLowerCase());
        });

        setData(filteredData);

    }

    return (
        <div style={{ height: '100vh', backgroundColor: '#f7f7f7' }}>
            <CustomDrawer>
                <Typography variant="h5" align="start" style={{ marginBottom: '30px', fontWeight: 'revert' }}>
                    Consultar
                </Typography>

                <Box style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '5px', border: '1px solid #ccc' }}>
                    <TextField
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton style={{ padding: '0px' }}>
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        value={search}
                        onChange={onChangeSearch}
                        fullWidth
                        id="search"
                        label="Buscar"
                        type='text'
                        autoComplete='off'
                    />

                    <div style={{ height: 400, width: '100%', marginTop: '20px' }}>
                        <StripedDataGrid
                            hideFooterSelectedRowCount
                            rowSelection={false}
                            rows={data}
                            columns={columns}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : ''
                            }
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}

                            pageSizeOptions={[5, 10]}
                        />
                    </div>
                </Box>


            </CustomDrawer>
        </div>


    );
}