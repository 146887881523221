import React from 'react';
import ReactDOM from 'react-dom/client';
import { CssBaseline } from '@mui/material';


import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { AppRoute } from './routes/AppRoute';

import { BrowserRouter } from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CssBaseline />
    <BrowserRouter>
      <AppRoute />
    </BrowserRouter>
  </React.StrictMode>
);

